import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { numbersOnly } from "../utilities/utils";
import { proxyApi } from "../utilities/proxy-api";
import { ApiRequest } from "../types/models/ApiRequest";
import {
  getCreditCardType,
  toFriendlyErrorMessage,
} from "../utilities/portaone-utils";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import { Helmet } from "react-helmet";

export default function Payment({ location }: any) {
  const activationInfoPrepaid = useSelector(
    (state: RootState) => state.activationInfo
  );
  const navigate = useNavigate();

  // Test credit card numbers from (Moneris)
  // Mastercard: 5454545454545454, 2222400041240011, 5454545442424242g
  // Visa: 4242424242424242, 4242424254545454
  // AMEX: 373599005095005, 373269005095005
  // Portaone API Docs Example: 4444333322221111

  const [cardNumber, setCardNumber] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCVV] = useState("");
  const [saveForAutopayment, setSaveForAutoPayment] = useState(false);
  const [cardType, setCardType] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCardNumber = (e: any) => {
    const value = numbersOnly(e.target.value);
    setCardNumber(value);
    const cardType = getCreditCardType(value);
    setCardType(cardType);
  };

  const handleCVV = (e: any) => {
    const value = numbersOnly(e.target.value);
    setCVV(value);
  };

  const handleExpirationDate = (e: any) => {
    const value = numbersOnly(e.target.value);
    if (value.length > 2) {
      const month = value.substring(0, 2);
      const year = value.substring(2);
      setExpirationDate(`${month}/${year}`);
      return;
    }
    setExpirationDate(value.replace("/", ""));
  };

  const canPay = () => {
    if (
      cardNumber.length < 15 ||
      cvv.length < 3 ||
      !nameOnCard ||
      expirationDate.length < 4 ||
      loading
    ) {
      return false;
    }
    return true;
  };

  const pay = () => {
    setErrorText("");
    setLoading(true);

    const expiryDate = expirationDate.split("/");
    const request = new ApiRequest({
      amount: activationInfoPrepaid.total, // from prepaidTotal
      cvv: cvv,
      expiry_month: expiryDate[0],
      expiry_year: expiryDate[1],
      name: nameOnCard,
      number: cardNumber,
      payment_method: cardType,
      customer_id: activationInfoPrepaid.us_i_customer, // from prepaidTotal
      save_card: saveForAutopayment,
      account_id: activationInfoPrepaid.us_i_account, // we have to get this from proxyApi.checkout.checkout
      delivery_complete: true,
      is_esim: true,
      plintron_plan_id: activationInfoPrepaid.us_plintron_plan_id, // from onClickRadio
      shipping_id: 10,
      sim_card_number: "",
      email: activationInfoPrepaid.email,
      activation_date: activationInfoPrepaid.us_activation_date, // from us_activation_date
      promo_code: "",
      int_call_id: activationInfoPrepaid.selected_intl_add_on,
    });
    proxyApi.transaction
      .creditCard(request)
      .then((res) => {
        if (!res.success && res.error) {
          setErrorText(toFriendlyErrorMessage(res.error));
          setLoading(false);
          console.error(res.error);
          return;
        }
        navigate(`/payment-thank-you`);
      })
      .catch((err) => {
        console.error(err);
        setErrorText("Something went wrong.");
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
            // <![CDATA[
            var _dcq = _dcq || [];
            var _dcs = _dcs || {};
            _dcs.account = '8316321';
          
            (function() {
              var dc = document.createElement('script');
              dc.type = 'text/javascript'; dc.async = true;
              dc.src = '//tag.getdrip.com/8316321.js';
              var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(dc, s);
            })();
            // ]]>
          `}
        </script>
      </Helmet>
      <div>
        <title>Checkout | PhoneBox</title>
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Card className="shadow border-0 p-5 w-md-75">
            <Image
              className="d-block"
              src="/images/phonebox_logo_color.png"
              width="170"
              height="30"
            ></Image>
            <Container className="p-md-5">
              <Row>
                <Col xs={12} lg={6}>
                  <div className="d-flex justify-content-center align-items-center text-center h-100">
                    <div className="my-4">
                      <p className="fs-6 mb-2 mt-2" style={{ fontWeight: 500 }}>
                        <span>Your total amount in</span>
                        <span
                          style={{
                            color: "#00c2f3",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                          }}
                        >
                          US Dollars
                        </span>
                        <span>is:</span>
                      </p>
                      {/* <h1 className="mt-0">USD ${shopDetails.total.toFixed(2)}</h1> */}
                      <h1 className="mt-0">
                        USD ${activationInfoPrepaid.total}
                      </h1>
                    </div>
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <Row>
                    <Col xs={12} xl={6} className="mb-4">
                      <Form.Group controlId="card-number">
                        <Form.Label>Card Number</Form.Label>
                        <Form.Control
                          placeholder="1234567812345678"
                          type="text"
                          value={cardNumber}
                          onChange={(e) => handleCardNumber(e)}
                          maxLength={16}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} xl={6} className="mb-4">
                      <Form.Group controlId="name-on-card">
                        <Form.Label>Name on Card</Form.Label>
                        <Form.Control
                          placeholder="James Smith"
                          type="text"
                          value={nameOnCard}
                          onChange={(e) => setNameOnCard(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} className="mb-4">
                      <Form.Group controlId="expiration-date">
                        <Form.Label>Expiration Date</Form.Label>
                        <Form.Control
                          placeholder="MM/YY"
                          type="text"
                          value={expirationDate}
                          onChange={(e) => handleExpirationDate(e)}
                          maxLength={5}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} className="mb-4">
                      <Form.Group controlId="cvv">
                        <Form.Label>CVV</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            value={cvv}
                            onChange={(e) => handleCVV(e)}
                            maxLength={4}
                          />
                          {/* <InputGroup.Text style={{ cursor: 'pointer' }} id="basic-addon2" onClick={() => alert('hey')}>
                            <AppPopover buttonHeight={20} buttonWidth={20} fontSize="15px" content={<p className="mb-0">This is the CVV stuff. What do you want here?</p>}></AppPopover>
                          </InputGroup.Text> */}
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* {shopDetails.planTypeD.toString() !== '7' && */}
                  {
                    <Row className="mb-4">
                      <Col xs={12}>
                        <Form.Group controlId="save-auto-payment">
                          <Form.Check
                            type="checkbox"
                            label="Save for Auto Payment"
                            onChange={(e) =>
                              setSaveForAutoPayment(e.target.checked)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  }
                  <Row className="mb-4">
                    <Col>
                      <Button
                        onClick={pay}
                        disabled={!canPay()}
                        className="text-uppercase d-block w-100"
                        variant="info"
                        style={{
                          color: "white",
                          height: "65px",
                          fontWeight: 500,
                        }}
                        size="lg"
                      >
                        {loading && (
                          <div className="d-flex justify-content-center align-items-center">
                            <div
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                            Please wait...
                          </div>
                        )}
                        {!loading && (
                          <>
                            <FontAwesomeIcon
                              icon={faLock}
                              className="me-2"
                            ></FontAwesomeIcon>
                            Confirm and Pay
                          </>
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <Button
                        disabled={loading}
                        className="text-uppercase d-block w-100"
                        variant="danger"
                        style={{ height: "65px", fontWeight: 500 }}
                        size="lg"
                        onClick={() => navigate("/")}
                      >
                        Cancel and Return
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {errorText && (
                        <p className="text-danger text-center fw-bold">
                          {errorText}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr className="mt-0" />
              <p className="text-center mb-0">Copyright © 2023 PhoneBox</p>
            </Container>
          </Card>
        </div>
      </div>
    </>
  );
}
