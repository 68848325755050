import { constants } from "../reduxConstants";
import dayjs from "dayjs";

const initialState = {
  simcardOrder: false,      // false.
  simcard_no: "",          // leave empty
  startDateLocal: dayjs(),
  endDateLocal: dayjs(),
  startDate: "",  // from handleActivationDateChange    // set
  endDate: "",   // from handleActivationDateChange    // set
  planId: 0,     // selectedPlanId    // set
  activationDateObject: dayjs(),
  activationDateString: "",
  esim: true,
  firstName: "",     // from handleformDataChange
  lastName: "",      // from handleformDataChange  
  email: "",   // from handleFormDataChange  
  emailConfirm: "",  // from handleFormDataChange
  serviceType: "",   // "leave empty"
  service_countryId: 42,  // from getServiceblhblahblah  // set by useeffect
  service_province: "",  // selected by user    // set by "handleStateMenuClick" & "handleProvinceMenuClick"
  service_city: "",   // to be selected by user    // set by handleCityChange
  delivery_countryId: 0,     // string version of get service country   // apparently unneeded as of now
  delivery_province: "",    // leave empty
  delivery_address: "",   // leave empty
  delivery_postal: "",   // leave empty
  currency: "CAD",  // set by country useEffect
  promocode: "",   // will be blank for now
  prepaidPromocode: "",
  bizId: 0,   // will be blank for now
  sfID: 2,  // will always be 2
  referral_cnum: "",  // will be blank for now
  consent_cem: false,
  us_activation_date: null, // for US checkout
  total: 0,
  us_plintron_plan_id: 0,
  us_i_customer: 0,
  us_main_i_product: 0, // from getDefaultPlans
  us_i_account: 0,  // from checkout.checkout
  payment_method: "",
  usPromocode: "",
  promocredit: 0,
  usSimCardNoCharge: false,
  countries: [],

  // portin
  portin_carrier: "", 
  portin_accountNo: "",
  portin_phoneNo: "",  
  portin_other: "",

  // us portin 
  us_portin_service_unitNo: "",
  us_portin_service_street_address: "",
  us_portin_service_city: "",
  us_portin_service_state_province: "",
  us_portin_service_country: "United States",
  us_portin_service_country_index: 247,
  us_portin_service_zip_postal: "",
  us_portin_service_full_address: "",
  us_portin_account_number: "",
  us_portin_pin_number: "",
  us_portin_phone_number: "",

  // us add ons
  selected_intl_add_on: null,

  // postpaid section:
  prorateDataCap: "",
  prorateStartDate: "",
  prorateEndDate: "",
  proratePct: "",
  prorate_duration: "",
  charge_duration: "",
  chargeStart: "",
  delivery_type: 0,
  delivery_needed: false,
}

const activationInfoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case constants.UPDATE_ACTIVATION_INFO:
      return {
        ...state,
        [Object.keys(action.payload)[0]]:
          action.payload[Object.keys(action.payload)[0]]
      };
    case constants.RESET_ACTIVATION_INFO_STATE:
      return initialState;
    default:
      return state;
  }
}

export default activationInfoReducer;