// hooks, react, redux
import { BaseSyntheticEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../hooks/useIsMobile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { updateActivationInfo } from "../redux/actions/activationInfoActions";
import { updateLocal } from "../redux/actions/localActions";

// components
import TakeMeThere from "../components/TakeMeThere";
import PlanSelectCard from "../components/PlanSelectCard";
import SwitchButton from "../components/SwitchButton";
import IMEICheck from "../components/IMEICheck";
import PlanPrice from "../components/PlanPrice";
import CustomPopup from "../components/CustomPopup";
import Footer from "../components/Footer";
import StatesDropDownList from "../components/StatesDropDownList";
import CountriesDropDownList from "../components/CountriesDropDownList";
import MobileFooter from "../components/MobileFooter";
import LearnMoreSection from "../components/LearnMoreSection";
import CanadaPostAutoCompleteAddress from "../components/CanadaPostAutoCompleteAddress";

// external components
import Spinner from "react-bootstrap/Spinner";
import { Dropdown, Form, Image, Button, InputGroup } from "react-bootstrap";
import { ConfigProvider, DatePicker } from "antd";
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// types
import { Step } from "../types/enums/Step";
import { ApiRequest } from "../types/models/ApiRequest";

// utilities
import {
  constants,
  colors,
  provinces,
  PAYMENT_METHOD_PAYPAL,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_ALIPAY_QR,
  PAYMENT_METHOD_WX_NATIVE,
  carriers,
  QUEBEC_AREA_CODES,
  INVALID_TELUS_CARRIERS,
  NO_US_PLANS_PARTNERS,
} from "../utilities/constants";
import { api } from "../utilities/api";
import { proxyApi } from "../utilities/proxy-api";
import {
  getPartnerIdFromPageLocation,
  submitAsForm,
  getServiceCountryId,
  getCanadaPlans,
} from "../utilities/utils";
import instance from "../utilities/axios";
import { validateEmail, numbersOnly } from "../utilities/utils";
import {
  encryptPromocode,
  getUSPlans,
  toFriendlyErrorMessage,
} from "../utilities/portaone-utils";

// third party integration
import Elevio from "elevio/lib/react";
import enUS from "antd/lib/locale/en_US";
import dayjs from "dayjs";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import { ApiResponse } from "../types/models/ApiResponse";

export default function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const activationInfoPrepaid = useSelector(
    (state: RootState) => state.activationInfo
  );
  const localVars = useSelector((state: RootState) => state.local);
  const step = localVars.step;
  const OaId = localVars.OaId;
  const selectedPlanId = localVars.selectedPlanId;
  const canadaPlansPrepaid = localVars.canadaPlansPrepaid;
  const canadaPlansPostpaid = localVars.canadaPlansPostpaid;
  const hasCanadaPlansPrepaid = localVars.canadaPlansPrepaid.length > 0;
  const hasCanadaPlansPostpaid = localVars.canadaPlansPostpaid.length > 0;
  const usPlans = localVars.usPlans;
  const country = localVars.country;
  const summaryData = localVars.summaryData;
  const isPostpaid = localVars.isPostpaid;
  const isCanada = country === constants.CANADA;
  const shouldRenderPortin = !isCanada || isPostpaid;
  const plans = isPostpaid
    ? canadaPlansPostpaid
    : isCanada
    ? canadaPlansPrepaid
    : usPlans;
  const countryColor = isCanada ? colors.BLUE : colors.GREEN;
  const transparentCountryColor = isCanada
    ? colors.TRANSPARENTBLUE
    : colors.TRANSPARENTGREEN;
  const currency = isCanada
    ? constants.CANADA_CURRENCY
    : constants.USA_CURRENCY;
  const selectedPlan = plans.filter(
    (plan) => plan.planId === selectedPlanId
  )[0];
  const [cemError, setCemError] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const [loading, setLoading] = useState(false);

  const [totalPaymentResponse, setTotalPaymentResponse] = useState<any>(null);

  const [planType, setPlanType] = useState(
    isCanada ? constants.POSTPAID_PLAN : constants.PREPAID_PLAN
  );
  const filteredPlans = plans.filter((plan) =>
    isPostpaid
      ? plan.planTypeD === ""
      : planType === constants.PREPAID_PLAN
      ? plan.planTypeD === "30"
      : planType === constants.NINETY_DAYS_PLAN
      ? plan.planTypeD === "90"
      : planType === constants.WEEKLY_PLAN
      ? plan.planTypeD === "7"
      : planType === constants.BIWEEKLY_PLAN
      ? plan.planTypeD === "14"
      : planType === constants.DAY_15_PLAN
      ? plan.planTypeD === "15"
      : false
  );

  const has90dayPlans =
    plans.filter((plan) => plan.planTypeD === "90").length > 0;
  const has30dayPlans =
    plans.filter((plan) => plan.planTypeD === "30").length > 0;
  const has14dayPlans =
    plans.filter((plan) => plan.planTypeD === "14").length > 0;
  const has15dayPlans =
    plans.filter((plan) => plan.planTypeD === "15").length > 0;
  const has7dayPlans =
    plans.filter((plan) => plan.planTypeD === "7").length > 0;
  const noPlansAvailable = filteredPlans.length === 0;
  // hide US plans for specific partners
  const doNotShowUSPlans = NO_US_PLANS_PARTNERS.includes(localVars.bn);
  const promocodeForDisplay = !isCanada
    ? activationInfoPrepaid.usPromocode
    : isPostpaid
    ? activationInfoPrepaid.promocode
    : activationInfoPrepaid.prepaidPromocode;
  // do not display SimCardNoCharge, it is the only promocode we do not show to end user.
  const displayPromocode =
    promocodeForDisplay.length > 0 &&
    promocodeForDisplay.toLowerCase() !== "simcardnocharge";

  const PlanCategoryTitleText = () => {
    if (!isCanada) {
      return (
        <Subtitle>5G Plans • Premium Networks at Affordable Prices</Subtitle>
      );
    } else {
      return (
        <Subtitle>
          Up to 1,000 minutes of International Calling • 4G/5G Network
        </Subtitle>
      );
    }
  };

  const UNLIMITEDText = () => (
    <div>{`UNLIMITED ${country}-wide Calling & Messaging`}</div>
  );

  let internationalCallingArticle = {
    title: "(List of countries)",
    articleId: 217,
  };
  let internationalCallingArticleUSA = {
    title: "25 countries",
    articleId: 110,
  };

  const DEFAULT_EMPTY_CARRIER = "Select a Carrier";

  const isTelusPlan = selectedPlan?.carrier.toLowerCase() === "telus";
  const isRogersPlan = selectedPlan?.carrier.toLowerCase() === "rogers";

  // form related
  const [serviceProvinceFullName, setServiceProvinceFullName] =
    useState("Select a Province");
  const cemChecked = activationInfoPrepaid.consent_cem === true;
  const [usaPhoneNumber, setUSAPhoneNumber] = useState(
    activationInfoPrepaid.us_portin_phone_number
      ? activationInfoPrepaid.us_portin_phone_number
      : ""
  );
  const [usaPINNumber, setUSAPINNumber] = useState("");
  const [usaAccountNumber, setUSAAccountNumber] = useState("");
  const [usaPortInVerifying, setUSAPortInVerifying] = useState(false);
  const [
    isServiceAddressAutoCompleteFinished,
    setIsServiceAddressAutoCompleteFinished,
  ] = useState(false);
  const [usPortinState, setUSPortinState] = useState({
    name: "Delaware",
    code: "DE",
  });
  const [isFreedomPortin, setIsFreedomPortin] = useState(false);

  // error related
  const [noPlanSelectedError, setNoPlanSelectedError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailSpecialCharError, setEmailSpecialCharError] = useState(false);
  const [emailExistError, setEmailExistError] = useState(false);
  const [emailMatchError, setEmailMatchError] = useState(false);
  const [noPaymentMethodSelectedError, setNoPaymentMethodSelectedError] =
    useState(false);
  const [invalidCarrier, setInvalidCarrier] = useState(false);
  const [invalidCanadianPortin, setInvalidCanadianPortin] = useState(false);
  const [usaPortinVerifyError, setUSAPortinVerifyError] = useState("");

  // payment-page related
  const [qrCode, setQrCode] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [isAlipay, setIsAlipay] = useState(false);
  const [iotPayId, setIotPayId] = useState("");
  const [postpaidModalVisibility, setPostpaidModalVisibility] = useState(false);

  const [intlCallingAddons, setIntlCallingAddons] = useState<any[]>([]);
  const [selectedIntlCallingAddon, setSelectedIntlCallingAddon] = useState<
    number | null
  >(null);

  // reset all activation info
  const resetActivationInfo = () => {
    setSelectedPaymentMethod("");

    if (isCanada) {
      setServiceProvinceFullName("Select a Province");
    } else {
      setServiceProvinceFullName("Select a State");
    }

    dispatch(updateLocal({ step: Step.First }) as any);
    dispatch(updateLocal({ isPortin: false }) as any);
    dispatch(updateLocal({ usPortInWireless: false }) as any);
    dispatch(updateLocal({ usPortInVerified: false }) as any);
    dispatch(updateLocal({ isNotAPOBox: false }) as any);

    dispatch(updateActivationInfo({ activationDateObject: null }) as any);
    dispatch(updateActivationInfo({ activationDateString: "" }) as any);
    dispatch(updateActivationInfo({ consent_cem: false }) as any);
    dispatch(updateActivationInfo({ email: "" }) as any);
    dispatch(updateActivationInfo({ emailConfirm: "" }) as any);
    dispatch(updateActivationInfo({ endDate: "" }) as any);
    dispatch(updateActivationInfo({ firstName: "" }) as any);
    dispatch(updateActivationInfo({ lastName: "" }) as any);
    dispatch(updateActivationInfo({ service_province: "" }) as any);
    dispatch(updateActivationInfo({ startDate: "" }) as any);
    dispatch(updateActivationInfo({ portin_carrier: "" }) as any);
    dispatch(updateActivationInfo({ portin_accountNo: "" }) as any);
    dispatch(updateActivationInfo({ portin_phoneNo: "" }) as any);
    dispatch(updateActivationInfo({ portin_other: "" }) as any);

    dispatch(updateActivationInfo({ us_portin_service_unitNo: "" }) as any);
    dispatch(
      updateActivationInfo({ us_portin_service_street_address: "" }) as any
    );
    dispatch(updateActivationInfo({ us_portin_service_city: "" }) as any);
    dispatch(
      updateActivationInfo({ us_portin_service_state_province: "" }) as any
    );

    dispatch(updateActivationInfo({ us_portin_service_zip_postal: "" }) as any);
    dispatch(
      updateActivationInfo({ us_portin_service_full_address: "" }) as any
    );
    dispatch(updateActivationInfo({ us_portin_account_number: "" }) as any);
    dispatch(updateActivationInfo({ us_portin_pin_number: "" }) as any);
    dispatch(updateActivationInfo({ us_portin_phone_number: "" }) as any);

    setUSAPhoneNumber("");
    setUSAPINNumber("");
    setUSAAccountNumber("");
    setIsServiceAddressAutoCompleteFinished(false);

    dispatch(
      updateLocal({
        summaryData: {
          planPrice: 0,
          chargeDuration: "",
          prorateDuration: "",
          taxes: 0,
          subTotal: 0,
          simCardFee: 0,
          taxRate: 0,
          prorateFee: 0,
        },
      }) as any
    );
  };

  const resetErrors = () => {
    setCemError(false);
    setErrorMessage("");
    setEmailSpecialCharError(false);
    setEmailExistError(false);
    setEmailMatchError(false);
  };

  useEffect(() => {
    const totalPlans = [
      ...canadaPlansPostpaid,
      ...canadaPlansPrepaid,
      ...usPlans,
    ];

    const params = new URLSearchParams(window.location.search);

    let foundPlanId = params.get("planid");

    const foundPlan = totalPlans.find((plan) => {
      return (
        plan.planId === Number(foundPlanId) ||
        plan.plintronPlanId === Number(foundPlanId)
      );
    });

    if (foundPlan) {
      dispatch(updateLocal({ selectedPlanId: foundPlan.planId }) as any);
      dispatch(updateActivationInfo({ planId: foundPlan.planId }) as any);
      if (foundPlan.plintronPlanId) {
        dispatch(
          updateActivationInfo({
            us_plintron_plan_id: foundPlan.plintronPlanId,
          }) as any
        );
        dispatch(updateLocal({ country: constants.USA }) as any);
      } else {
        dispatch(updateLocal({ country: constants.CANADA }) as any);
      }

      let isPostpaidForUpdate = foundPlan.planTypeD === "";
      dispatch(updateLocal({ isPostpaid: isPostpaidForUpdate }) as any);

      // update step
      dispatch(updateLocal({ step: Step.Second }) as any);
    }
  }, [plans]);

  useEffect(() => {
    dispatch(updateActivationInfo({ promocode: "" }) as any);
    dispatch(updateActivationInfo({ prepaidPromocode: "" }) as any);
    dispatch(
      updateActivationInfo({
        activationDateObject: dayjs(activationInfoPrepaid.activationDateObject),
      }) as any
    );
    let partnerIds = getPartnerIdFromPageLocation(window.location);
    dispatch(updateLocal({ bn: partnerIds.canadaPartnerId }) as any);
    getCanadaPlans(dispatch, partnerIds.canadaPartnerId);
    getUSPlans(dispatch, partnerIds.usPartnerId);

    // RBC partner id, remove for security
    // save in local storage
    if (partnerIds.canadaPartnerId === 4036) {
      window.history.pushState(
        {},
        "Monthly Plans, Prepaid Plans | PhoneBox",
        "/"
      );
      sessionStorage.setItem("raLink", "true");
    }
    // New Code
    setLoading(true);
    proxyApi.addons
      .getIntlCallingAddons()
      .then((response: ApiResponse) => {
        if (response.success) {
          setIntlCallingAddons(response.data.product_list);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // Till Here
  }, []);

  useEffect(() => {
    // this effect should only happen when it's in First Step, where user can select plans
    // letting user accidentally change plan type in the middle of the process will cause errors.
    if (step === Step.First) {
      // if no plans are available, change the selected plan type,
      // we should not show "no plans available" as default.
      if (noPlansAvailable && isCanada) {
        if (hasCanadaPlansPostpaid) {
          setPlanType(constants.POSTPAID_PLAN);
          dispatch(updateLocal({ isPostpaid: true }) as any);
        } else if (hasCanadaPlansPrepaid) {
          setPlanType(constants.PREPAID_PLAN);
        }
      }

      // if is USA and no plans available,
      if (noPlansAvailable && !isCanada) {
        setPlanType(constants.PREPAID_PLAN);
      }
    }
  }, [plans, country]);

  useEffect(() => {
    // going back to or beginning at step one should reset all info (personal info, product info)
    if (step === Step.First) {
      resetActivationInfo();
    }
  }, [step]);

  useEffect(() => {
    dispatch(
      updateActivationInfo({ payment_method: selectedPaymentMethod }) as any
    );
  }, [selectedPaymentMethod, dispatch]);

  useEffect(() => {
    // reset certain fields on country switch
    if (country === constants.USA) {
      setServiceProvinceFullName("Select a State");

      if (planType === constants.NINETY_DAYS_PLAN) {
        setPlanType(constants.PREPAID_PLAN);
      }
    } else {
      setServiceProvinceFullName("Select a Province");
    }

    if (step === Step.First) {
      dispatch(updateLocal({ selectedPlanId: undefined }) as any);
    }

    let currency =
      country === constants.USA
        ? constants.USA_CURRENCY
        : constants.CANADA_CURRENCY;
    dispatch(
      updateActivationInfo({
        service_countryId: getServiceCountryId(country),
      }) as any
    );
    dispatch(updateActivationInfo({ currency: currency }) as any);
  }, [country, dispatch]);

  useEffect(() => {
    if (selectedPlan?.planTypeD !== "30") {
      setSelectedIntlCallingAddon(null);
    }
  }, [selectedPlan]);

  const onChangeCountry = (changeCountry: string) => {
    if (changeCountry === country) {
      return;
    }
    if (changeCountry === constants.CANADA) {
      dispatch(updateLocal({ isPostpaid: true }) as any);
    } else if (changeCountry === constants.USA) {
      dispatch(updateLocal({ isPostpaid: false }) as any);
    }
    setNoPlanSelectedError(false);
    dispatch(updateLocal({ country: changeCountry }) as any);
  };

  const onChangePlanType = (planType: string) => {
    dispatch(updateLocal({ selectedPlanId: undefined }) as any);
    setNoPlanSelectedError(false);
    setPlanType(planType);
  };

  const popupCloseHandler = () => {
    setVisibility(false);
    setIotPayId("");
  };

  const postpaidPopupCloseHandler = () => {
    setPostpaidModalVisibility(false);
  };

  const calculateCalendarDates = (current: any) => {
    let customDate = dayjs().format("YYYY-MM-DD");
    let returnVal = current && current < dayjs(customDate, "YYYY-MM-DD");

    return returnVal;
  };

  const getTotalPrepaid = () => {
    setLoading(true);
    const encodedUSPromocode = activationInfoPrepaid.usPromocode
      ? encryptPromocode(activationInfoPrepaid.usPromocode)
      : "";

    // all work for USA
    if (country === constants.USA) {
      proxyApi.checkout
        .prepaidTotal(
          new ApiRequest({
            activation_date: activationInfoPrepaid.activationDateObject,
            email: activationInfoPrepaid.email,
            first_name: activationInfoPrepaid.firstName,
            i_rep: null,
            last_name: activationInfoPrepaid.lastName,
            plan_fee: selectedPlan.plan_fee,
            plan_subscription_id: selectedPlan.subscriptionId,
            promo_code: encodedUSPromocode,
            promo_credit: activationInfoPrepaid.promocredit,
            shipping_code_country: "US",
            shipping_code_type: "RE",
            shipping_fee: 0,
            shipping_i_subscription: 10, // this translates to "$0" for portaOne shipping.
            sim_card_number: "",
            zip: "",
            is_esim: true,
            int_call_id: selectedIntlCallingAddon,
          })
        )
        .then((res) => {
          let tax_rate = (res.data.tax_amount / res.data.subtotal) * 100;

          dispatch(
            updateActivationInfo({
              selected_intl_add_on: selectedIntlCallingAddon,
            }) as any
          );
          dispatch(
            updateLocal({
              summaryData: {
                ...summaryData,
                planPrice: selectedPlan?.plan_fee || 0,
                subTotal: res.data.total,
                taxes: res.data.tax_amount,
                simCardFee: res.data.sim_card_fee,
                taxRate: +tax_rate.toFixed(2),
              },
            }) as any
          );

          const resTotal = res.data.total;
          const resCustId = res.data.customer_id;

          proxyApi.checkout
            .checkout(
              new ApiRequest({
                activation_date: activationInfoPrepaid.activationDateObject, // from something
                address_line_1: "", // leave empty
                address_line_2: "", // leave empty
                city: "", // leave empty
                contact_number: "", // leave empty
                i_customer: res.data.customer_id, // from available resources
                i_product: activationInfoPrepaid.us_main_i_product, // from initial get plans call
                is_esim: true, // always true
                is_sim_card_order: false,
                port_in: localVars.isPortin,
                port_in_details: {
                  account_number:
                    activationInfoPrepaid.us_portin_account_number,
                  account_password: activationInfoPrepaid.us_portin_pin_number,
                  address_line_1:
                    activationInfoPrepaid.us_portin_service_street_address,
                  address_line_2:
                    activationInfoPrepaid.us_portin_service_unitNo,
                  city: activationInfoPrepaid.us_portin_service_city,
                  phone_number: activationInfoPrepaid.us_portin_phone_number,
                  state: activationInfoPrepaid.us_portin_service_state_province,
                  zip: activationInfoPrepaid.us_portin_service_zip_postal,
                },
                shipping_code_country: "US",
                shipping_code_country_iso: "US",
                shipping_code_type: "RE",
                state: "",
                zip: "",
              })
            )
            .then((response) => {
              dispatch(
                updateActivationInfo({
                  us_i_account: response.data.i_account,
                }) as any
              );
              dispatch(updateActivationInfo({ total: resTotal }) as any);
              dispatch(
                updateActivationInfo({ us_i_customer: resCustId }) as any
              );

              setLoading(false);
              dispatch(updateLocal({ step: Step.Third }) as any);
            })
            .catch((err) => {
              setLoading(false);
              console.error(err);
            });
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
      return;
    }

    const bn = new URLSearchParams(window.location.search).get("bn");
    const prepaidPayload = {
      orderSimCard: true,
      simcard_no: "",
      planID: selectedPlanId,
      countryId: getServiceCountryId(country),
      provinceCode: activationInfoPrepaid.service_province,
      start: activationInfoPrepaid.startDate,
      end: activationInfoPrepaid.endDate,
      sfID: 0, // no shipping for esim
      promocode: activationInfoPrepaid.prepaidPromocode,
      esim: true,
      bn: bn ? +bn : 0,
    };

    const postpaidPayload = {
      orderSimCard: true,
      planID: selectedPlanId,
      countryId: getServiceCountryId(country),
      provinceCode: activationInfoPrepaid.service_province,
      start: activationInfoPrepaid.startDate,
      promocode: activationInfoPrepaid.promocode,
      sfID: 0, // no shipping for esim
      esim: true,
      bn: bn ? +bn : 0,
    };

    const payload = isPostpaid ? postpaidPayload : prepaidPayload;

    const getTotalUrl = isPostpaid
      ? "/Activation/GetTotalPostpaid"
      : "/Activation/GetTotalPrepaid";

    // CANADA section
    instance
      .get(getTotalUrl, { params: payload })
      .then((response) => {
        const data = response.data[0];
        setTotalPaymentResponse(data);
        dispatch(
          updateLocal({
            summaryData: {
              ...summaryData,
              planPrice: selectedPlan?.plan_fee || 0,
              taxes: data.gst_Amt + data.pst_Amt,
              subTotal: data.total,
              simCardFee: data.simcard_amt,
              taxRate: (data.gst_rate + data.pst_rate).toFixed(2),
              chargeDuration: isPostpaid
                ? data.charge_Duration
                : summaryData.chargeDuration,
              prorateDuration: isPostpaid ? data.prorate_Duration : "",
              prorateFee: isPostpaid ? data.prorate_Amt.toFixed(2) : 0,
            },
          }) as any
        );

        dispatch(updateActivationInfo({ promocredit: data.promo_Amt }) as any);

        // generate OaId
        const url = isPostpaid
          ? "/Activation/PostActivationInfoPostpaid"
          : "/Activation/PostActivationInfoPrepaid";

        let promocodeForPostActivationInfo = isPostpaid
          ? activationInfoPrepaid.promocode
          : activationInfoPrepaid.prepaidPromocode;

        let payload = {
          ...activationInfoPrepaid,
          firstName: activationInfoPrepaid.firstName,
          lastName: activationInfoPrepaid.lastName,
          email: activationInfoPrepaid.email,
          plan_fee: data.plan_Amt,
          simcard_fee: data.simcard_amt,
          sfFee: data.shipping_Fee,
          // hardcode sfID as zero
          sfID: 0,
          prorate_fee: data.prorate_Amt.toFixed(2),
          charge_duration: data.charge_Duration,
          gst_rate: data.gst_rate,
          pst_rate: data.pst_rate,
          gst_amt: data.gst_Amt,
          pst_amt: data.pst_Amt,
          subtotal: data.subtotal,
          promocredit: data.promo_Amt,
          promocode: promocodeForPostActivationInfo,
          total: data.total,
          bizId:
            activationInfoPrepaid.bizId === 0
              ? null
              : activationInfoPrepaid.bizId,
        };

        if (isPostpaid) {
          // postpaid logic
          // same payload but with 6 more fields
          payload = {
            ...payload,
            prorateDataCap: data.prorate_DataCap,
            prorateStartDate: data.prorate_Start,
            prorateEndDate: data.prorate_End,
            proratePct: data.prorate_Pct,
            prorate_duration: data.prorate_Duration,
            chargeStart: data.charge_Start,

            // must set endDate to empty string
            endDate: "",
          };

          dispatch(
            updateActivationInfo({
              prorateDataCap: data.prorate_DataCap,
            }) as any
          );
          dispatch(
            updateActivationInfo({
              prorateStartDate: data.prorate_Start,
            }) as any
          );
          dispatch(
            updateActivationInfo({ prorateEndDate: data.prorate_End }) as any
          );
          dispatch(
            updateActivationInfo({ proratePct: data.prorate_Pct }) as any
          );
          dispatch(
            updateActivationInfo({
              prorate_duration: data.prorate_Duration,
            }) as any
          );
          dispatch(
            updateActivationInfo({
              charge_duration: data.charge_Duration,
            }) as any
          );
          dispatch(
            updateActivationInfo({ chargeStart: data.charge_Start }) as any
          );
        }

        instance
          .post(url, payload)
          .then((response) => {
            let data = response.data;
            if (data === 0) {
              alert("Sorry, something went wrong with generating your order.");
            } else {
              dispatch(updateLocal({ OaId: data }) as any);
              dispatch(updateLocal({ step: Step.Third }) as any);
            }
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const compareEmailAddress = (email: string, emailConfirm: string) => {
    if (email.toLowerCase() !== emailConfirm.toLowerCase()) {
      setEmailMatchError(true);
    } else {
      setEmailMatchError(false);
      if (emailConfirm) {
        validateEmailAddress(emailConfirm);
      }
    }
  };

  const handleProvinceMenuClick = (e: any) => {
    const key = +e.key;
    let province = provinces.find((s) => s.key === key);
    if (province) {
      switch (province.code) {
        case "NT":
        case "NU":
        case "YT":
          alert(
            "The Area Code of this province is not available at this time. We will, however, provide you with 416 or 647 Area code as a substitute of this. Please note that this won't affect your service as our plans include unlimited Canada wide calling and texting which allows you to call and text anyone inside Canada with our phone numbers."
          );
          break;
        case "QC":
          // QC plans are fine for Telus.
          if (isTelusPlan) {
            break;
          }
          alert(
            "514 and 438 Area Codes are not available at this time. We will, however, provide you with 416, 437 or 647 Area code as a substitute of this. Please note that this won't affect your service as our plans include unlimited Canada wide calling and texting which allows you to call and text anyone inside Canada with our phone numbers."
          );
          break;
      }
    }
    let service_province = !province ? "" : province.code;
    let service_province_fullname = !province
      ? "Select a Province"
      : province.name;
    dispatch(
      updateActivationInfo({ service_province: service_province }) as any
    );
    setServiceProvinceFullName(service_province_fullname);
  };

  const handleCityChange = (e: any) => {
    switch (e.target.value.toLowerCase()) {
      case "thunder bay":
        alert(
          "The area code of this city is not available at this time. We will, however, provide you with a 416 or 617 Area Code as a substitute of this. Please note that this won't affect your service as our plans include unlimited Canada wide calling and texting which allows you to call and text anyone inside Canada with our phone numbers."
        );
        break;
    }

    dispatch(updateActivationInfo({ service_city: e.target.value }) as any);
  };

  const handleFormDataChange = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;
    dispatch(updateActivationInfo({ [name]: value }) as any);
  };

  const validateEmailAddress = (email: string) => {
    if (country === constants.USA) {
      setEmailExistError(false);
      setEmailSpecialCharError(false);

      if (!validateEmail(email)) {
        setEmailSpecialCharError(true);
        return;
      }

      // Used to test
      // ccc@gmail.com exists
      proxyApi.account
        .getAccountList(
          new ApiRequest({
            email: email,
          })
        )
        .then((res) => {
          if (res.data.account_list.length > 0) {
            setEmailExistError(true);
          } else {
            setEmailExistError(false);
          }
        })
        .catch((err) => {
          console.error(
            "validateEmailAddress() /Account/get_account_list",
            err
          );
        });
      return;
    }

    api
      .checkIfEmailExisting(email)
      .then((response) => {
        let data = response.data;
        if (data) {
          setEmailExistError(true);
        } else {
          setEmailExistError(false);
        }
      })
      .catch((error) => {
        console.error(
          "validateEmailAddress() /Activation/CheckIfEmailExisting",
          error.response.data
        );
      });
  };

  const handleActivationDateChange = (newDate: any) => {
    if (selectedPlan) {
      let startDateLocalInDayjs = dayjs(newDate);
      // in postpaid, we ignore chargeDuration
      let endDateLocalInDayjs = isPostpaid
        ? dayjs(newDate)
        : dayjs(newDate).add(+selectedPlan.planTypeD - 1, "days");

      dispatch(
        updateLocal({
          summaryData: {
            ...summaryData,
            chargeDuration: `${startDateLocalInDayjs.format(
              "MMM DD, YYYY"
            )} ~ ${endDateLocalInDayjs.format("MMM DD, YYYY")}`,
          },
        }) as any
      );

      dispatch(updateActivationInfo({ activationDateObject: newDate }) as any);
      dispatch(
        updateActivationInfo({
          activationDateString: dayjs(newDate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          ),
        }) as any
      );
      dispatch(
        updateActivationInfo({ startDateLocal: startDateLocalInDayjs }) as any
      );
      dispatch(
        updateActivationInfo({ endDateLocal: endDateLocalInDayjs }) as any
      );
      dispatch(
        updateActivationInfo({
          startDate: dayjs(startDateLocalInDayjs).format("YYYY-MM-DD 00:00:00"),
        }) as any
      );
      dispatch(
        updateActivationInfo({
          endDate: dayjs(endDateLocalInDayjs).format("YYYY-MM-DD 00:00:00"),
        }) as any
      );
      dispatch(updateActivationInfo({ us_activation_date: newDate }) as any);
    } else {
      dispatch(updateLocal({ step: Step.First }) as any);
    }
  };

  const handlePortin = (value: boolean) => {
    if (localVars.isPortin === value) {
      return;
    }

    if (country === constants.CANADA && value) {
      alert(
        "You will receive a SMS to authorize porting your phone number from your old carrier. Please reply within 90 minutes from when you received it to complete the process. Please keep your old sim card active until you lose service with your old provider."
      );
    }

    let portin_carrier = value
      ? { portin_carrier: localVars.portinCarrier }
      : { portin_carrier: "" };

    let portin_accountNo = value
      ? { portin_accountNo: localVars.portinAccountNo }
      : { portin_acocuntNo: "" };

    let portin_phoneNo = value
      ? { portin_phoneNo: localVars.portinPhoneNo }
      : { portin_phoneNo: "" };

    let portin_other = value
      ? { portin_other: localVars.portinOther }
      : { portin_other: "" };

    dispatch(updateActivationInfo(portin_carrier) as any);
    dispatch(updateActivationInfo(portin_accountNo) as any);
    dispatch(updateActivationInfo(portin_phoneNo) as any);
    dispatch(updateActivationInfo(portin_other) as any);

    dispatch(updateLocal({ isPortin: value }) as any);
    dispatch(updateLocal({ usPortInWireless: false }) as any);
    dispatch(updateLocal({ usPortInVerified: false }) as any);
  };

  const handleCanadianPortin = (event: BaseSyntheticEvent) => {
    let newPortinNumber = event.target.value.replace(/[^\d.-]+/g, "");
    if (localVars.country === constants.CANADA && isRogersPlan) {
      if (
        QUEBEC_AREA_CODES.some(
          (areaCode) =>
            newPortinNumber.startsWith(areaCode) ||
            newPortinNumber.startsWith("1" + areaCode)
        )
      ) {
        setInvalidCanadianPortin(true);
        return;
      }
    }

    setInvalidCanadianPortin(false);
    dispatch(updateLocal({ portinPhoneNo: event.target.value }) as any);
    dispatch(
      updateActivationInfo({ portin_phoneNo: event.target.value }) as any
    );
  };

  const handleWirelessPortin = (value: boolean) => {
    if (localVars.usPortInWireless === value) {
      return;
    }

    dispatch(updateLocal({ usPortInWireless: value }) as any);
    dispatch(updateLocal({ usPortInVerified: false }) as any);
  };

  const handleUSAPhoneNumber = (e: BaseSyntheticEvent) => {
    const value = e.target.value;
    setUSAPhoneNumber(value);
    dispatch(updateActivationInfo({ us_portin_phone_number: value }) as any);
  };

  const handleUSAAccountNumber = (e: BaseSyntheticEvent) => {
    const value = e.target.value;
    setUSAAccountNumber(e.target.value);
    dispatch(updateActivationInfo({ us_portin_account_number: value }) as any);
  };

  const handleUSAPINNumber = (e: BaseSyntheticEvent) => {
    const value = numbersOnly(e.target.value);
    setUSAPINNumber(value);
    dispatch(updateActivationInfo({ us_portin_pin_number: value }) as any);
  };

  const handlePortInVerify = () => {
    setUSAPortinVerifyError("");
    setUSAPortInVerifying(true);
    proxyApi.plintron
      .extMnpCheckPortinEligibility(
        new ApiRequest({ pmsisdn: `1${usaPhoneNumber}` })
      )
      .then((res) => {
        const verified = res.success;
        const errorMessage =
          "Sorry, the phone number you entered is not eligible for port in, please check with your current service provider to find out.";
        dispatch(updateLocal({ usPortInVerified: verified }) as any);
        if (!verified) {
          setUSAPortinVerifyError(errorMessage);
        }
        setUSAPortInVerifying(false);
      })
      .catch((err) => {
        console.error(err);
        setUSAPortinVerifyError(errorMessage);
        setUSAPortInVerifying(false);
      });
  };

  const verifyActivationInfo = (): boolean => {
    if (emailMatchError || emailExistError || emailSpecialCharError) {
      return false;
    }

    if (
      isFreedomPortin &&
      activationInfoPrepaid.portin_accountNo.length !== 15
    ) {
      setErrorMessage(
        "Error: IMEI should only be 15 digits. Please kindly double check again."
      );
      return false;
    }

    // disable eslint just for the regex
    /* eslint-disable-next-line */
    const emailReg =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    /* eslint-disable-next-line */
    const everythingButChineseCharacters = /^[\u0000-\u4DFF\u9FA6-\uFFFF]*$/;
    const validationMap = new Map<string, any>();
    if (country === constants.CANADA) {
      validationMap.set(
        "Service Province",
        activationInfoPrepaid.service_province
      );
    }
    validationMap.set("First Name", activationInfoPrepaid.firstName);
    validationMap.set("Last Name", activationInfoPrepaid.lastName);
    validationMap.set("Email", activationInfoPrepaid.email);
    validationMap.set(
      "Activation Date",
      activationInfoPrepaid.activationDateObject
    );
    validationMap.set("Confirm Email", activationInfoPrepaid.emailConfirm);

    if (localVars.isPortin) {
      if (country === constants.CANADA) {
        validationMap.set(
          "Carrier",
          activationInfoPrepaid.portin_carrier === DEFAULT_EMPTY_CARRIER
            ? ""
            : activationInfoPrepaid.portin_carrier
        );
        validationMap.set(
          "Account Number",
          activationInfoPrepaid.portin_accountNo
        );
        validationMap.set("Phone Number", activationInfoPrepaid.portin_phoneNo);
      }

      if (country === constants.USA) {
        if (!localVars.usPortInWireless) {
          validationMap.set(
            "Wireless Port In Phone Number",
            localVars.usPortInWireless
          );
        }

        if (!localVars.usPortInVerified) {
          validationMap.set(
            "Verified Port In Phone Number",
            localVars.usPortInVerified
          );
        }

        validationMap.set(
          "Account Number",
          activationInfoPrepaid.us_portin_account_number
        );
        validationMap.set(
          "PIN Number",
          activationInfoPrepaid.us_portin_pin_number
        );
        validationMap.set(
          "Port In Service Street Address",
          activationInfoPrepaid.us_portin_service_street_address
        );
        validationMap.set(
          "Port In Service City",
          activationInfoPrepaid.us_portin_service_city
        );
        validationMap.set(
          "Port In Service Country",
          activationInfoPrepaid.us_portin_service_country
        );
        validationMap.set(
          country === constants.CANADA
            ? "Port In Service Province"
            : "Port In Service State",
          activationInfoPrepaid.us_portin_service_state_province
        );
        validationMap.set(
          country === constants.CANADA
            ? "Port In Service Postal Code"
            : "Port In Service Zip Code",
          activationInfoPrepaid.us_portin_service_zip_postal
        );
        validationMap.set(
          "Port In Address Must Not Be a P.O. Box",
          localVars.isNotAPOBox
        );
      }
    }

    // start validation, check if port-in number is invalid
    if (invalidCarrier) {
      setErrorMessage("The port in carrier you have selected is invalid.");
      return false;
    }

    if (invalidCanadianPortin) {
      setErrorMessage(
        "Sorry, the phone number you've entered is not eligible to Port In."
      );
      return false;
    }

    // do rest of validations
    for (const entry of Array.from(validationMap.entries())) {
      const key = entry[0];
      const value = entry[1];
      if (!value || value.toString().trim() === "") {
        setErrorMessage(`${key} is required.`);
        return false;
      }

      switch (key) {
        case "Email":
        case "Confirm Email":
          if (!emailReg.test(value)) {
            setErrorMessage("Please fill in a correct email address.");
            return false;
          }
          break;

        case "Activation Date":
          break;

        default:
          if (!everythingButChineseCharacters.test(value)) {
            setErrorMessage("Please fill " + key + " with number or alphabet.");
            return false;
          }
      }
    }

    setErrorMessage("");

    if (!cemChecked && country === constants.CANADA) {
      setCemError(true);
      return false;
    } else {
      setCemError(false);
    }

    return true;
  };

  const onClickLogo = () => {
    resetActivationInfo();
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set("planid", "");

    if (sessionStorage.getItem("raLink")) {
      window.location.replace("https://gophonebox.com/plans/?bn=4036");
      return;
    } else if (params.size > 0) {
      window.location.replace("https://gophonebox.com/?" + params.toString());
      return;
    }
    window.location.replace("https://gophonebox.com");
  };

  const handleCarrierMenuClick = (e: any) => {
    const key = +e.key;
    let carrier = carriers.find((s) => s.key === key);
    let name = !carrier ? DEFAULT_EMPTY_CARRIER : carrier.name;

    dispatch(updateActivationInfo({ portin_carrier: name }) as any);
    dispatch(updateLocal({ portinCarrier: name }) as any);

    if (name.toLowerCase() === "freedom") {
      setIsFreedomPortin(true);
    } else {
      setIsFreedomPortin(false);
    }

    if (INVALID_TELUS_CARRIERS.includes(name) && isTelusPlan) {
      setInvalidCarrier(true);
      return;
    } else if (name.toLowerCase() === "rogers" && isRogersPlan) {
      // cannot do Rogers to Rogers portin
      setInvalidCarrier(true);
      return;
    } else {
      setInvalidCarrier(false);
    }
  };

  const getServiceAddressAutoComplete = (full_address: any) => {
    const longCountryName = full_address[5];
    const fullAddress = `${full_address[0]}, ${full_address[1]}, ${full_address[2]}, ${longCountryName}, ${full_address[4]}`;
    dispatch(
      updateActivationInfo({
        us_portin_service_street_address: full_address[0],
      }) as any
    );
    dispatch(
      updateActivationInfo({ us_portin_service_city: full_address[1] }) as any
    );
    dispatch(
      updateActivationInfo({
        us_portin_service_country: longCountryName,
      }) as any
    );
    dispatch(
      updateActivationInfo({
        us_portin_service_state_province: full_address[2],
      }) as any
    );
    dispatch(
      updateActivationInfo({
        us_portin_service_zip_postal: full_address[4],
      }) as any
    );
    dispatch(
      updateActivationInfo({
        us_portin_service_full_address: fullAddress,
      }) as any
    );
    setUSPortinState({ name: full_address[6], code: full_address[2] });
    setIsServiceAddressAutoCompleteFinished(true);
  };

  // const onClickEditPlanSelection = () => {
  //   resetActivationInfo();
  //   resetErrors();
  //   dispatch(updateLocal({ step: Step.First }) as any);
  // }

  const onClickEditInformation = () => {
    dispatch(
      updateLocal({
        summaryData: {
          planPrice: 0,
          chargeDuration: "",
          prorateDuration: "",
          taxes: 0,
          subTotal: 0,
          simCardFee: 0,
          taxRate: 0,
          prorateFee: 0,
        },
      }) as any
    );
    setSelectedPaymentMethod("");
    resetErrors();
    dispatch(updateLocal({ step: Step.Second }) as any);
  };

  const onClickBack = () => {
    if (step === Step.Third) {
      onClickEditInformation();
    } else {
      // remove planid on pressing back
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.set("planid", "");
      window.history.replaceState(
        {},
        document.title,
        window.origin + "/?" + params.toString()
      );

      resetActivationInfo();
      resetErrors();
      dispatch(updateLocal({ step: Step.previous(step) }) as any);
    }
  };

  const onClickNext = () => {
    // user must select a plan to advance
    if (selectedPlanId === undefined) {
      setNoPlanSelectedError(true);
      return;
    } else {
      setNoPlanSelectedError(false);
    }

    if (step !== Step.Second) {
      if (step === Step.First) {
        const params = new URLSearchParams(window.location.search);
        params.set("planid", selectedPlanId);
        window.history.replaceState(
          {},
          document.title,
          window.origin + "/?" + params.toString()
        );
      }
      let nextStep = Step.after(step);
      dispatch(updateLocal({ step: nextStep }) as any);
    }

    // step two, we need to verify form data
    if (step === Step.Second && verifyActivationInfo()) {
      // this function also sets "step" to Step.Third
      getTotalPrepaid();
      setPostpaidModalVisibility(isPostpaid);
    }
  };

  const checkCountry = (varToChange: string) => {
    switch (varToChange) {
      case "stateProvince":
        return country === constants.USA ? "State" : "Province";
      case "zipPostal":
        return country === constants.USA ? "Zip Code" : "Postal Code";
    }
  };

  const onClickPay = () => {
    if (!selectedPaymentMethod || loading) {
      setNoPaymentMethodSelectedError(true);
      return;
    } else {
      setNoPaymentMethodSelectedError(false);
      // handlePayment
      if (country === constants.CANADA) {
        if (!OaId) {
          alert("Shopping info is not available for payment.");
        }

        const headerKey = isPostpaid ? "oaid" : "paid";
        instance.defaults.headers.post[headerKey] = `${OaId}`;

        if (selectedPaymentMethod === PAYMENT_METHOD_WX_NATIVE) {
          setIsAlipay(false);
        } else if (selectedPaymentMethod === PAYMENT_METHOD_ALIPAY_QR) {
          setIsAlipay(true);
        }

        const thankYouUrl = window.location.origin + "/payment-thank-you";
        const cancelUrl = window.location.href;
        const postpaidUrl = `/Activation/PostpaidActivationBilling?billtype=${selectedPaymentMethod}&return_url=${thankYouUrl}&cancel_url=${cancelUrl}`;
        const prepaidUrl = `/Activation/PrepaidActivationBilling?billtype=${selectedPaymentMethod}&return_url=${thankYouUrl}&cancel_url=${cancelUrl}&currency=${activationInfoPrepaid.currency}`;
        const url = isPostpaid ? postpaidUrl : prepaidUrl;
        instance
          .post(url)
          .then((response) => {
            let data = response.data;
            if (data.startsWith("Failed:")) {
              if (data.endsWith("already paid?")) {
                alert(
                  "It looks like you may have already paid for the service! Click OK to refresh."
                );
                navigate("/payment-thank-you");
              } else {
                alert(
                  "Something went wrong with your payment. Please try again in a moment."
                );
              }
            } else if (
              selectedPaymentMethod === PAYMENT_METHOD_CREDIT_CARD ||
              selectedPaymentMethod === PAYMENT_METHOD_PAYPAL
            ) {
              window.location = data;
            } else {
              // wechat pay and alipay
              let qrArr = data.split("^");
              if (qrArr.length === 2) {
                const iotPayId = qrArr[0];
                let qrCodeString = qrArr[1];
                setIotPayId(iotPayId);
                setQrCode(qrCodeString);
                setVisibility(true);
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (country === constants.USA) {
        switch (selectedPaymentMethod) {
          case PAYMENT_METHOD_CREDIT_CARD:
            navigate("/payment");
            break;
          case PAYMENT_METHOD_PAYPAL:
            const payPalTransactionRequest = new ApiRequest({
              amount: activationInfoPrepaid.total,
              customer_id: activationInfoPrepaid.us_i_customer,
              return_url: `${window.location.origin}/payment-thank-you?i_payment_transaction={{PayPalTransactionId}}&payment_method=${PAYMENT_METHOD_PAYPAL}`,
              cancel_url: window.location.href,
            });
            proxyApi.transaction
              .payPal(payPalTransactionRequest)
              .then((res) => {
                if (!res.success && res.error) {
                  setErrorMessage(toFriendlyErrorMessage(res.error));
                  console.error(res.error);
                  return;
                }
                // redirects to paypal
                submitAsForm(res.data.pay_url, res.data.payload);
              })
              .catch((err) => {
                console.error(err);
              });
            break;
        }
      }
    }
  };

  const RenderFirstStepTopSection = () => {
    return (
      <>
        <div
          style={{
            position: "relative",
            marginBottom: isMobile ? "300px" : "130px",
          }}
        >
          {!isMobile && (
            <PlanFeaturesCard>
              <div
                style={{
                  marginRight: "auto",
                  marginLeft: "20px",
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Subtitle color={countryColor}>Country</Subtitle>
                {/* list */}
                <div>
                  {/* list item */}
                  <CountrySelectRadio
                    $countrycolor={countryColor}
                    $lightcountrycolor={colors.LIGHTBLUE}
                    selected={country === constants.CANADA}
                    onClick={() => onChangeCountry(constants.CANADA)}
                  >
                    <AlignRow>
                      <input
                        type="radio"
                        className="radio-blue"
                        value={constants.CANADA}
                        checked={country === constants.CANADA}
                        readOnly
                      />
                      <div>Canada</div>
                    </AlignRow>
                    <img
                      src="/images/canada_720.png"
                      alt="Canada flag"
                      style={{ width: 40 }}
                    />
                  </CountrySelectRadio>
                  {!doNotShowUSPlans && (
                    <CountrySelectRadio
                      $countrycolor={countryColor}
                      $lightcountrycolor={colors.LIGHTGREEN}
                      selected={country === constants.USA}
                      onClick={() => onChangeCountry(constants.USA)}
                    >
                      <AlignRow>
                        <input
                          type="radio"
                          className="radio-green"
                          value={constants.USA}
                          checked={country === constants.USA}
                          readOnly
                        />
                        <div>USA</div>
                      </AlignRow>
                      <img
                        src="/images/usa_720.png"
                        alt="USA flag"
                        style={{ width: 40 }}
                      />
                    </CountrySelectRadio>
                  )}
                </div>
              </div>
              <Divider />
              <div
                style={{
                  marginRight: "auto",
                  marginLeft: "20px",
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Subtitle color={countryColor}>Plan Type</Subtitle>
                <AlignRow style={{ marginTop: "8px" }}>
                  {isCanada && hasCanadaPlansPostpaid && (
                    <IconPlanTag
                      color={countryColor}
                      selected={isPostpaid}
                      onClick={() => {
                        dispatch(
                          updateLocal({ country: constants.CANADA }) as any
                        );
                        dispatch(updateLocal({ isPostpaid: true }) as any);
                        setPlanType(constants.POSTPAID_PLAN);
                      }}
                    >
                      Monthly Plans
                      {!isPostpaid ? (
                        <div style={{ height: "30px" }}>
                          <img
                            src="/images/postpaid-blue.png"
                            alt="postpaid"
                            height="30px"
                          />
                        </div>
                      ) : (
                        <div style={{ height: "30px" }}>
                          <img
                            src="/images/postpaid.png"
                            alt="postpaid"
                            height="30px"
                          />
                        </div>
                      )}
                    </IconPlanTag>
                  )}
                  {(!isCanada || hasCanadaPlansPrepaid) && (
                    <IconPlanTag
                      color={countryColor}
                      selected={!isPostpaid}
                      onClick={() => {
                        setPlanType(constants.PREPAID_PLAN);
                        dispatch(updateLocal({ isPostpaid: false }) as any);
                      }}
                    >
                      Prepaid Plans
                      {!isPostpaid ? (
                        <div style={{ height: "30px", marginLeft: "4px" }}>
                          <img
                            src="/images/prepaid.png"
                            alt="prepaid"
                            height="24px"
                            style={{ marginTop: "3px" }}
                          />
                        </div>
                      ) : (
                        <div style={{ height: "30px", marginLeft: "4px" }}>
                          <img
                            src="/images/prepaid-blue.png"
                            alt="prepaid"
                            height="24px"
                            style={{ marginTop: "3px" }}
                          />
                        </div>
                      )}
                    </IconPlanTag>
                  )}
                </AlignRow>
              </div>
            </PlanFeaturesCard>
          )}
          {isMobile && (
            <PlanFeaturesCard $isMobile={isMobile}>
              <div
                style={{
                  marginRight: "20px",
                  marginLeft: "20px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div>
                  <Subtitle color={countryColor}>Country</Subtitle>
                  {/* list */}
                  <div>
                    {/* list item */}
                    <CountrySelectRadio
                      $countrycolor={countryColor}
                      $lightcountrycolor={colors.LIGHTBLUE}
                      selected={country === constants.CANADA}
                      onClick={() => onChangeCountry(constants.CANADA)}
                    >
                      <AlignRow>
                        <input
                          type="radio"
                          className="radio-blue"
                          value={constants.CANADA}
                          checked={country === constants.CANADA}
                          readOnly
                        />
                        <div>Canada</div>
                      </AlignRow>
                      <img
                        src="/images/canada_720.png"
                        alt="Canada flag"
                        style={{ width: 40 }}
                      />
                    </CountrySelectRadio>
                    {!doNotShowUSPlans && (
                      <CountrySelectRadio
                        $countrycolor={countryColor}
                        $lightcountrycolor={colors.LIGHTGREEN}
                        selected={country === constants.USA}
                        onClick={() => onChangeCountry(constants.USA)}
                      >
                        <AlignRow>
                          <input
                            type="radio"
                            className="radio-green"
                            value={constants.USA}
                            checked={country === constants.USA}
                            readOnly
                          />
                          <div>USA</div>
                        </AlignRow>
                        <img
                          src="/images/usa_720.png"
                          alt="USA flag"
                          style={{ width: 40 }}
                        />
                      </CountrySelectRadio>
                    )}
                  </div>
                </div>
                <div style={{ margin: "10px 0px 10px 0px" }}>
                  <Divider $horizontal />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height:
                      isCanada && hasCanadaPlansPostpaid ? "120px" : "80px",
                  }}
                >
                  <Subtitle color={countryColor}>Plan Type</Subtitle>
                  {/* list */}
                  {isCanada && hasCanadaPlansPostpaid && (
                    <IconPlanTag
                      color={countryColor}
                      selected={isPostpaid}
                      onClick={() => {
                        dispatch(
                          updateLocal({ country: constants.CANADA }) as any
                        );
                        dispatch(updateLocal({ isPostpaid: true }) as any);
                      }}
                    >
                      Monthly Plans
                      {!isPostpaid ? (
                        <div style={{ height: "30px" }}>
                          <img
                            src="/images/postpaid-blue.png"
                            alt="postpaid"
                            height="30px"
                          />
                        </div>
                      ) : (
                        <div style={{ height: "30px" }}>
                          <img
                            src="/images/postpaid.png"
                            alt="postpaid"
                            height="30px"
                          />
                        </div>
                      )}
                    </IconPlanTag>
                  )}
                  {(!isCanada || hasCanadaPlansPrepaid) && (
                    <IconPlanTag
                      color={countryColor}
                      selected={!isPostpaid}
                      onClick={() => {
                        setPlanType(constants.PREPAID_PLAN);
                        dispatch(updateLocal({ isPostpaid: false }) as any);
                      }}
                    >
                      Prepaid Plans
                      {!isPostpaid ? (
                        <div style={{ height: "30px", marginLeft: "4px" }}>
                          <img
                            src="/images/prepaid.png"
                            alt="prepaid"
                            height="24px"
                            style={{ marginTop: "3px" }}
                          />
                        </div>
                      ) : (
                        <div style={{ height: "30px", marginLeft: "4px" }}>
                          <img
                            src="/images/prepaid-blue.png"
                            alt="prepaid"
                            height="24px"
                            style={{ marginTop: "3px" }}
                          />
                        </div>
                      )}
                    </IconPlanTag>
                  )}
                </div>
              </div>
            </PlanFeaturesCard>
          )}
        </div>
      </>
    );
  };

  // useState and useEffect for RenderYourPlan.
  const [shouldRenderVertical, setShouldRenderVertical] = useState(false);
  // render back button on top of the nav div if the innerWidth is less than 1440px.
  const [shouldRenderBackButtonTop, setShouldRenderBackButtonTop] =
    useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth > 767 && window.innerWidth < 1200) {
        setShouldRenderVertical(true);
      } else {
        setShouldRenderVertical(false);
      }

      if (window.innerWidth < 1440) {
        setShouldRenderBackButtonTop(true);
      } else {
        setShouldRenderBackButtonTop(false);
      }
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [shouldRenderVertical]);

  const RenderYourPlan = () => {
    let isUnlimited = selectedPlan?.plan_fee === 55 && !isCanada;
    let dataText = isUnlimited
      ? "Unlimited*"
      : selectedPlan?.data
      ? selectedPlan.data
      : 0;

    return (
      <div
        style={{
          padding: "20px",
          textAlign: "left",
          width: isMobile ? "100%" : "33%",
        }}
      >
        <Header style={{ paddingBottom: "5px" }}>Your Plan</Header>
        <div
          style={{
            display: "flex",
            flexDirection: shouldRenderVertical ? "column-reverse" : "row",
            padding: "5px",
          }}
        >
          <img src="/images/phone.png" height="140px" alt="mobile phone" />
          <div style={{ marginLeft: "16px", textAlign: "left" }}>
            <DataLabel
              $color={countryColor}
              $lightColor={transparentCountryColor}
              $small
              style={{ marginBottom: "10px" }}
            >
              {dataText}
            </DataLabel>
            <PlanPrice
              price={selectedPlan?.plan_fee ? selectedPlan.plan_fee : 0}
              currency={currency}
              color={countryColor}
              timeUnit={selectedPlan?.planTypeD ? selectedPlan.planTypeD : ""}
            ></PlanPrice>
            <span style={{ fontSize: 10 }}>
              {isUnlimited ? `*Speed reduces after ${selectedPlan?.data}` : ""}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const InformationPaymentSwitchNav = () => {
    return (
      <div
        style={{
          width: isMobile ? "350px" : "500px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: isMobile ? "20px" : "50px",
          marginBottom: "50px",
          userSelect: "none",
        }}
      >
        <AlignRow>
          <NavHeader
            $activeStep={step}
            $highlightStep={Step.Second}
            $color={countryColor}
            onClick={() => onClickEditInformation}
          >
            Information
          </NavHeader>
          <NavHeader
            $activeStep={step}
            $highlightStep={Step.Third}
            $color={countryColor}
          >
            Payment
          </NavHeader>
        </AlignRow>
        <div style={{ backgroundColor: "#E5E5E5" }}>
          <HR color={countryColor} step={step} />
        </div>
      </div>
    );
  };

  const RenderPlanInfo = () => {
    // const has300min = selectedPlan?.planId !== 7;
    // const isUSWeekly = selectedPlan?.planTypeD === "7" && !isCanada;
    const isUnlimited =
      (selectedPlan?.plan_fee === 55 && !isCanada) ||
      selectedPlan?.data === "Unlimited";
    const dataText = isUnlimited
      ? "Unlimited*"
      : selectedPlan?.data
      ? selectedPlan.data
      : 0;
    const isRogers = selectedPlan?.carrier === "Rogers";

    if (isCanada) {
      return (
        <>
          <Subtitle>Details</Subtitle>
          <UNLIMITEDText />
          <ul style={{ marginTop: "20px" }}>
            {!isUnlimited && (
              <DetailItem>{`${
                selectedPlan?.data ? selectedPlan.data : 0
              } high speed 4G LTE/5G data`}</DetailItem>
            )}
            {isRogers && (
              <DetailItem>{`Supported on the ${
                selectedPlan?.carrier ? selectedPlan.carrier : ""
              } Network`}</DetailItem>
            )}
            <DetailItem>Call Display</DetailItem>
            <DetailItem>Voicemail - Up to 35 minutes of messages</DetailItem>
            {!isUnlimited && (
              <DetailItem>Data Overage Rate: $15 / 1GB</DetailItem>
            )}
            {!isRogers && (
              <DetailItem>
                Up to 1,000 minutes of International Calling
                <InternationalCallingLink
                  data-elevio-article={internationalCallingArticle.articleId}
                  style={{ marginLeft: "4px" }}
                >
                  {internationalCallingArticle.title}
                </InternationalCallingLink>
              </DetailItem>
            )}
          </ul>
        </>
      );
    } else {
      return (
        <>
          <Subtitle>Details</Subtitle>
          <UNLIMITEDText />
          <ul style={{ marginTop: "20px" }}>
            <DetailItem>Unlimited Nationwide Talk & Text</DetailItem>
            <DetailItem>{`${dataText} data at up to 5G LTE`}</DetailItem>
            {/* {has300min && !isUSWeekly && (
              <DetailItem>
                300 minutes of international talk to
                <InternationalCallingLink
                  data-elevio-article={internationalCallingArticleUSA.articleId}
                  style={{ marginLeft: "4px" }}
                >
                  {internationalCallingArticleUSA.title}
                </InternationalCallingLink>
              </DetailItem>
            )} */}
          </ul>
          <span style={{ fontSize: 10 }}>
            {isUnlimited ? `*Speed reduces after ${selectedPlan?.data}` : ""}
          </span>
        </>
      );
    }
  };

  const RenderPleaseNote = () => {
    const has300min = selectedPlan?.planId !== 7;
    const isUSWeekly = selectedPlan?.planTypeD === "7" && !isCanada;
    const hasIntlAddon = selectedPlan?.planTypeD === "30" && !isCanada;

    return (
      <>
        <Subtitle>Please Note</Subtitle>
        <ul style={{ margin: "5px 0px" }}>
          <DetailItem>Plan does not include International Roaming</DetailItem>
          {isCanada && (
            <DetailItem>{`Provincial 9-1-1 fees are extra (if applicable)`}</DetailItem>
          )}
          {!has300min && !isCanada && (
            <DetailItem>
              International calling available as an add-on for $10
            </DetailItem>
          )}
          <DetailItem>A SIM card fee of $10 will be charged</DetailItem>
          {isUSWeekly && (
            <>
              <DetailItem>
                International calling option is unavailable for this plan
              </DetailItem>
              <DetailItem>The plan is strictly limited to 7 days</DetailItem>
              <DetailItem>Term extension is unavailable</DetailItem>
              <DetailItem>SIM card is not allowed to be reused</DetailItem>
            </>
          )}
          {hasIntlAddon && (
            <>
              <DetailItem>
                International Call Add-on:
                <InternationalCallingLink
                  data-elevio-article={internationalCallingArticleUSA.articleId}
                  style={{ marginLeft: "4px" }}
                >
                  {internationalCallingArticleUSA.title}
                </InternationalCallingLink>
              </DetailItem>
              <ul>
                <DetailItem>500 international minutes for $5</DetailItem>
                <DetailItem>Unlimited minutes for $10</DetailItem>
              </ul>
            </>
          )}
          {!isCanada && <DetailItem>Data Top-up rates: $10/GB</DetailItem>}
        </ul>
      </>
    );
  };

  const BackButton = (props: { style?: React.CSSProperties }) => {
    return (
      <div
        style={{
          ...props.style,
          color: "#000000",
          fontSize: "24px",
          cursor: "pointer",
          width: "fit-content",
          marginLeft: "12px",
        }}
        onClick={onClickBack}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          style={{ marginRight: "12px" }}
          size="1x"
        />
        Back
      </div>
    );
  };

  // renders the nav which also contains some summary information.
  const RenderSharedNavContent = () => {
    return (
      <div style={{ position: "relative" }}>
        {/* NAV CONTENT FOR MOBILE */}
        {shouldRenderBackButtonTop && (
          <>
            <BackButton style={{ marginTop: "24px" }} />
            {isMobile && <InformationPaymentSwitchNav />}
          </>
        )}
        {!shouldRenderBackButtonTop && (
          <BackButton
            style={{
              position: "absolute",
              left: -150,
              top: 15,
              marginTop: "0px",
            }}
          />
        )}
        <BlueContainer
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            marginRight: isMobile ? "10px" : "0px",
            marginLeft: isMobile ? "10px" : "0px",
            marginTop: "20px",
          }}
        >
          <RenderYourPlan />
          <Divider $horizontal={isMobile} />
          <div style={{ padding: "20px", width: isMobile ? "100%" : "33%" }}>
            {step === Step.Second && <RenderPlanInfo />}
            {step === Step.Third && (
              <>
                {isPostpaid ? (
                  <>
                    <AlignRowJustify>
                      <Subtitle>First Whole Month</Subtitle>
                      <Subtitle color={countryColor}>
                        ${summaryData.planPrice.toFixed(2)}
                      </Subtitle>
                    </AlignRowJustify>
                    <div style={{ color: "#999", fontSize: "12px" }}>
                      {summaryData.chargeDuration}
                    </div>
                    <AlignRowJustify>
                      <Subtitle>Partial Fee</Subtitle>
                      <Subtitle color={countryColor}>
                        ${summaryData.prorateFee}
                      </Subtitle>
                    </AlignRowJustify>
                    <div style={{ color: "#999", fontSize: "12px" }}>
                      {summaryData.prorateDuration}
                    </div>
                  </>
                ) : (
                  <>
                    <AlignRowJustify>
                      <Subtitle>Plan Price</Subtitle>
                      <Subtitle color={countryColor}>
                        ${summaryData.planPrice.toFixed(2)}
                      </Subtitle>
                    </AlignRowJustify>
                    <div style={{ color: "#999", fontSize: "12px" }}>
                      {`${selectedPlan?.planTypeD} days | ${summaryData.chargeDuration}`}
                    </div>
                  </>
                )}
                <Divider
                  $horizontal
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                {selectedIntlCallingAddon && (
                  <>
                    <AlignRowJustify>
                      <Subtitle>Add On</Subtitle>
                      <Subtitle color={countryColor}>
                        $
                        {intlCallingAddons
                          .find(
                            (a) =>
                              a?.i_subscription === selectedIntlCallingAddon
                          )
                          ?.product_subscription?.activation_fee?.toFixed(2)}
                      </Subtitle>
                    </AlignRowJustify>
                    <div style={{ color: "#999", fontSize: "12px" }}>
                      {
                        intlCallingAddons.find(
                          (a) => a?.i_subscription === selectedIntlCallingAddon
                        )?.end_user_name
                      }
                    </div>
                    <Divider
                      $horizontal
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    />
                  </>
                )}
                {/* previously conditional based on simCardFee > 0 */}
                <AlignRowJustify>
                  <Subtitle>eSIM Card Fee</Subtitle>
                  <Subtitle color={countryColor}>${(10).toFixed(2)}</Subtitle>
                </AlignRowJustify>
                {totalPaymentResponse !== null &&
                totalPaymentResponse.esim_promo_not_applied ? null : (
                  <AlignRowJustify>
                    <Subtitle>eSIM Promotion</Subtitle>
                    <Subtitle color={countryColor}>
                      -${(10).toFixed(2)}
                    </Subtitle>
                  </AlignRowJustify>
                )}
                {displayPromocode && (
                  <AlignRowJustify>
                    <Subtitle>{promocodeForDisplay}</Subtitle>
                    <Subtitle color={countryColor}>
                      - ${activationInfoPrepaid.promocredit.toFixed(2)}
                    </Subtitle>
                  </AlignRowJustify>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Subtitle>
                    Taxes{` (${(+summaryData.taxRate).toFixed(0)}%)`}
                  </Subtitle>
                  <Subtitle color={countryColor}>
                    ${summaryData.taxes.toFixed(2)}
                  </Subtitle>
                </div>
                <Divider
                  $horizontal
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <AlignRowJustify>
                  <Subtitle style={{ fontSize: "18px" }}>Sub Total</Subtitle>
                  <Subtitle color={countryColor} style={{ fontSize: "18px" }}>
                    ${summaryData.subTotal.toFixed(2)}
                  </Subtitle>
                </AlignRowJustify>
              </>
            )}
          </div>
          <Divider $horizontal={isMobile} />
          <div style={{ padding: "20px", width: isMobile ? "100%" : "33%" }}>
            {step === Step.Second && <RenderPleaseNote />}
            {step === Step.Third && (
              <>
                <Subtitle>Summary</Subtitle>
                <Divider
                  $horizontal
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
                <AlignRowJustify style={{ whiteSpace: "nowrap" }}>
                  <Subtitle>Name:</Subtitle>
                  <div
                    style={{ marginLeft: "10px", minWidth: 100 }}
                  >{`${activationInfoPrepaid.firstName} ${activationInfoPrepaid.lastName}`}</div>
                </AlignRowJustify>
                <AlignRowJustify style={{ whiteSpace: "nowrap" }}>
                  <Subtitle>Email:</Subtitle>
                  <div style={{ marginLeft: "10px", minWidth: 100 }}>
                    {activationInfoPrepaid.email}
                  </div>
                </AlignRowJustify>
                <AlignRowJustify>
                  <Subtitle>Activation Date:</Subtitle>
                  <div style={{ marginLeft: "10px", minWidth: 100 }}>
                    {activationInfoPrepaid.activationDateString}
                  </div>
                </AlignRowJustify>
              </>
            )}
          </div>
        </BlueContainer>
        {/* NAV CONTENT FOR DESKTOP */}
        {!isMobile && <InformationPaymentSwitchNav />}
      </div>
    );
  };

  return (
    <div>
      <>
        {/* init elevio */}
        <Elevio accountId="604012fe69c1d" />
        <Helmet>
          <script type="text/javascript">
            {`
              // <![CDATA[
              var _dcq = _dcq || [];
              var _dcs = _dcs || {};
              _dcs.account = '8316321';
            
              (function() {
                var dc = document.createElement('script');
                dc.type = 'text/javascript'; dc.async = true;
                dc.src = '//tag.getdrip.com/8316321.js';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(dc, s);
              })();
              // ]]>
            `}
          </script>
        </Helmet>
      </>
      <>
        {/** on mobile, hide this image past the first step. */}
        {(!isMobile || step === Step.First) && (
          <div style={{ position: "relative" }}>
            <BackgroundImage>
              <div
                style={{
                  position: "absolute",
                  zIndex: 1,
                  top: isMobile ? 10 : 20,
                  left: isMobile ? 10 : 20,
                  cursor: "pointer",
                }}
                onClick={onClickLogo}
              >
                <Image
                  height={isMobile ? 20 : 30}
                  src={"/images/phonebox_logo_white.png"}
                ></Image>
              </div>
              <div
                style={{
                  maxWidth: "500px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Title
                  style={{
                    zIndex: 2,
                    position: "relative",
                    top: isMobile ? 10 : 0,
                  }}
                >
                  Connect in a Flash
                  <br />
                  with eSIM!
                </Title>
              </div>
            </BackgroundImage>
            <BGImageOverlay $isCanada={isCanada} />
          </div>
        )}
        {step === Step.First && <RenderFirstStepTopSection />}
      </>
      {/* BEGIN ALL THREE STEP CONTROLS */}
      <div
        style={{
          marginTop: isMobile ? "50px" : step === Step.First ? "120px" : "0px",
          height: "fit-content",
          marginBottom: "50px",
        }}
      >
        <SmallContainer>
          {/* SECTION ONE  // PLAN SELECT */}
          {step === Step.First && (
            <div style={{ marginLeft: isMobile ? "20px" : "0px" }}>
              <div
                style={{
                  width: "fit-content",
                  paddingTop: "12px",
                  margin: "24px auto",
                }}
              >
                <PlanCategoryTitleText />
                <UNLIMITEDText />
              </div>
              {noPlansAvailable && (
                <div style={{ textAlign: "center" }}>
                  No plans available in the selected category.
                </div>
              )}
              <div style={{ width: "100%" }}>
                {!isPostpaid && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      width: "fit-content",
                      margin: "auto auto 24px auto",
                    }}
                  >
                    {has90dayPlans && (
                      <PlanTag
                        color={countryColor}
                        selected={planType === constants.NINETY_DAYS_PLAN}
                        onClick={() =>
                          onChangePlanType(constants.NINETY_DAYS_PLAN)
                        }
                      >
                        90 Days Plans
                        {planType === constants.NINETY_DAYS_PLAN && (
                          <div
                            className="plan-tag-arrow"
                            color={countryColor}
                          />
                        )}
                      </PlanTag>
                    )}
                    {has30dayPlans && (
                      <PlanTag
                        color={countryColor}
                        selected={planType === constants.PREPAID_PLAN}
                        onClick={() => onChangePlanType(constants.PREPAID_PLAN)}
                      >
                        30 Days Plans
                        {planType === constants.PREPAID_PLAN && (
                          <div
                            className="plan-tag-arrow"
                            color={countryColor}
                          />
                        )}
                      </PlanTag>
                    )}
                    {has15dayPlans && (
                      <PlanTag
                        color={countryColor}
                        selected={planType === constants.DAY_15_PLAN}
                        onClick={() => onChangePlanType(constants.DAY_15_PLAN)}
                      >
                        15 Days Plans
                        {planType === constants.DAY_15_PLAN && (
                          <div
                            className="plan-tag-arrow"
                            color={countryColor}
                          />
                        )}
                      </PlanTag>
                    )}
                    {has14dayPlans && (
                      <PlanTag
                        color={countryColor}
                        selected={planType === constants.BIWEEKLY_PLAN}
                        onClick={() =>
                          onChangePlanType(constants.BIWEEKLY_PLAN)
                        }
                      >
                        14 Days Plans
                        {planType === constants.BIWEEKLY_PLAN && (
                          <div
                            className="plan-tag-arrow"
                            color={countryColor}
                          />
                        )}
                      </PlanTag>
                    )}
                    {has7dayPlans && (
                      <PlanTag
                        color={countryColor}
                        selected={planType === constants.WEEKLY_PLAN}
                        onClick={() => onChangePlanType(constants.WEEKLY_PLAN)}
                      >
                        7 Days Plans
                        {planType === constants.WEEKLY_PLAN && (
                          <div
                            className="plan-tag-arrow"
                            color={countryColor}
                          />
                        )}
                      </PlanTag>
                    )}
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "inline-block",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {filteredPlans.map((plan) => (
                  <PlanSelectCard
                    plan={plan}
                    key={plan.planId}
                    onClickNext={onClickNext}
                  ></PlanSelectCard>
                ))}
                {!isMobile && (
                  <div
                    style={{
                      width: "240px",
                      display: "inline-block",
                      verticalAlign: "top",
                      position: "relative",
                      marginTop: "15px",
                    }}
                  >
                    <img
                      src={
                        isCanada
                          ? "/images/esim-banner-canada.png"
                          : "/images/esim-banner-usa.png"
                      }
                      alt="esim banner"
                      width="250px"
                      style={{ borderRadius: 10 }}
                    />
                  </div>
                )}
              </div>
              <div style={{ marginTop: "20px" }}>
                {noPlanSelectedError && (
                  <ErrorMessage>Kindly choose a plan.</ErrorMessage>
                )}
              </div>
            </div>
          )}
          {/** SHARED NAV CONTENT AND PLAN INFO FOR SECTIONS TWO AND THREE */}
          {step !== Step.First && <RenderSharedNavContent />}
          {/** SECTION TWO  // INFORMATION */}
          {step === Step.Second && (
            <div
              style={{
                margin: isMobile ? "50px 10px 0px 10px" : "0px 0px 0px 0px",
              }}
            >
              <Subtitle>Input Your Information</Subtitle>
              <div style={{ marginBottom: "20px" }}>
                You are just a few clicks away!
              </div>
              <GrayContainer
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "20px",
                  paddingBottom: "30px",
                }}
              >
                <InputContainerRow $vertical={isMobile}>
                  <InputContainer>
                    <Label>
                      First Name<span style={{ color: colors.RED }}>*</span>
                    </Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      onChange={(event) => handleFormDataChange(event)}
                      value={activationInfoPrepaid.firstName}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label>
                      Last Name<span style={{ color: colors.RED }}>*</span>
                    </Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      onChange={(event) => handleFormDataChange(event)}
                      value={activationInfoPrepaid.lastName}
                    />
                  </InputContainer>
                </InputContainerRow>
                <InputContainerRow $vertical={isMobile}>
                  <InputContainer>
                    <Label>
                      Email Address<span style={{ color: colors.RED }}>*</span>
                    </Label>
                    <Form.Control
                      type="text"
                      name="email"
                      onChange={(event) => {
                        const email = event.target.value;
                        handleFormDataChange(event);
                        compareEmailAddress(
                          email,
                          activationInfoPrepaid.emailConfirm
                        );
                      }}
                      value={activationInfoPrepaid.email}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label>
                      Confirm Email Address
                      <span style={{ color: colors.RED }}>*</span>
                    </Label>
                    <Form.Control
                      type="text"
                      name="emailConfirm"
                      onChange={(event) => {
                        const emailConfirm = event.target.value;
                        handleFormDataChange(event);
                        compareEmailAddress(
                          activationInfoPrepaid.email,
                          emailConfirm
                        );
                      }}
                      value={activationInfoPrepaid.emailConfirm}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </InputContainer>
                </InputContainerRow>
                {/** province selection row, unnecessary for USA */}
                {country === constants.CANADA && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <InputContainer>
                      <Label>
                        Where will you be using our service?
                        <span style={{ color: colors.RED }}>*</span>
                      </Label>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" className="w-100">
                          {serviceProvinceFullName}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {provinces.map((p) => (
                            <Dropdown.Item
                              key={p.key}
                              onClick={() => handleProvinceMenuClick(p)}
                            >
                              {p.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputContainer>
                    <InputContainer>
                      <Form.Control
                        type="text"
                        placeholder={"Enter a city"}
                        onChange={handleCityChange}
                      />
                    </InputContainer>
                  </div>
                )}
                <InputContainerRow $vertical={isMobile}>
                  <InputContainer>
                    <Label>
                      Activation Date
                      <span style={{ color: colors.RED }}>*</span>
                    </Label>
                    <ConfigProvider locale={enUS}>
                      <DatePicker
                        id="activationDatePicker"
                        value={activationInfoPrepaid.activationDateObject}
                        disabledDate={calculateCalendarDates}
                        style={{ width: "300px", padding: "10px" }}
                        onChange={(val) => handleActivationDateChange(val)}
                      />
                    </ConfigProvider>
                  </InputContainer>
                  {/* TODO: remove this empty container */}
                  {!isMobile && <InputContainer />}
                </InputContainerRow>
              </GrayContainer>
              {shouldRenderPortin && (
                <>
                  <Subtitle style={{ marginTop: "30px", marginBottom: "30px" }}>
                    Port In
                  </Subtitle>
                  <GrayContainer
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "20px",
                      paddingBottom: "30px",
                    }}
                  >
                    {/* accommodate spacing of InputContainerRow */}
                    <WideInputContainer $mobile={isMobile}>
                      <Label>
                        {`Do you want to port in your number? (${
                          isCanada ? "Canadian" : "US"
                        } numbers only)`}
                        <span style={{ color: colors.RED }}>*</span>
                      </Label>
                      <AlignRowJustify style={{ width: "150px" }}>
                        <AlignRow>
                          <input
                            type="radio"
                            className={isCanada ? "radio-blue" : "radio-green"}
                            checked={localVars.isPortin}
                            onChange={() => handlePortin(true)}
                          />
                          <div style={{ paddingLeft: "4px" }}>Yes</div>
                        </AlignRow>
                        <AlignRow>
                          <input
                            type="radio"
                            className={isCanada ? "radio-blue" : "radio-green"}
                            checked={!localVars.isPortin}
                            onChange={() => handlePortin(false)}
                          />
                          <div style={{ paddingLeft: "4px" }}>No</div>
                        </AlignRow>
                      </AlignRowJustify>
                    </WideInputContainer>
                    {/* US port in */}
                    {localVars.isPortin && country === constants.USA && (
                      <>
                        {localVars.isPortin && (
                          <WideInputContainer $mobile={isMobile}>
                            <Label>
                              Is your number a wireless number?
                              <span style={{ color: colors.RED }}>*</span>
                            </Label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "150px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  className={
                                    isCanada ? "radio-blue" : "radio-green"
                                  }
                                  checked={localVars.usPortInWireless}
                                  onChange={() => handleWirelessPortin(true)}
                                />
                                <div style={{ paddingLeft: "4px" }}>Yes</div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  className={
                                    isCanada ? "radio-blue" : "radio-green"
                                  }
                                  checked={!localVars.usPortInWireless}
                                  onChange={() => handleWirelessPortin(false)}
                                />
                                <div style={{ paddingLeft: "4px" }}>No</div>
                              </div>
                            </div>
                            {!localVars.usPortInWireless && (
                              <small className="d-block text-danger mt-3">
                                Sorry, we are not able to port in landline
                                numbers at this moment.
                              </small>
                            )}
                          </WideInputContainer>
                        )}
                        {localVars.isPortin && localVars.usPortInWireless && (
                          <InputContainerRow>
                            <InputContainer>
                              <Label>Please enter your port in number:</Label>
                              <InputGroup>
                                <Form.Control
                                  type="tel"
                                  value={usaPhoneNumber}
                                  onChange={handleUSAPhoneNumber}
                                  maxLength={10}
                                />
                                <Button
                                  variant="primary"
                                  onClick={() => handlePortInVerify()}
                                  disabled={
                                    usaPhoneNumber.length !== 10 ||
                                    usaPortInVerifying
                                  }
                                >
                                  {!usaPortInVerifying && <div>Verify</div>}
                                  {usaPortInVerifying && (
                                    <div className="d-flex justify-content-center align-items-center">
                                      <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Verifying...
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </Button>
                              </InputGroup>
                            </InputContainer>
                            {!localVars.usPortInVerified &&
                              usaPortinVerifyError.length > 0 && (
                                <small className="text-danger mt-2">
                                  {usaPortinVerifyError}
                                </small>
                              )}
                            {!isMobile && <InputContainer />}
                          </InputContainerRow>
                        )}
                        {localVars.isPortin &&
                          localVars.usPortInWireless &&
                          localVars.usPortInVerified && (
                            <>
                              <WideInputContainer $mobile={isMobile}>
                                <hr className="mt-4 w-25 mr-auto" />
                              </WideInputContainer>
                              <WideInputContainer $mobile={isMobile}>
                                <Label>
                                  Provide more details about your current phone
                                  number.
                                </Label>
                                <small>
                                  Please contact your current provider to get
                                  the account number, PIN number, and the
                                  services address that is associated with your
                                  account.
                                </small>
                              </WideInputContainer>
                              <InputContainerRow $vertical={isMobile}>
                                <InputContainer>
                                  <Label>Account Number:</Label>
                                  <Form.Control
                                    type="text"
                                    value={usaAccountNumber}
                                    onChange={handleUSAAccountNumber}
                                    maxLength={20}
                                  />
                                </InputContainer>
                                <InputContainer>
                                  <Label>PIN Number:</Label>
                                  <Form.Control
                                    type="text"
                                    value={usaPINNumber}
                                    onChange={handleUSAPINNumber}
                                    maxLength={15}
                                  />
                                </InputContainer>
                              </InputContainerRow>
                              <WideInputContainer $mobile={isMobile}>
                                <Label>Service Address:</Label>
                                <small>
                                  The service address must be the same as your
                                  current services provider's invoice,
                                  otherwise, the port in may not succeed.
                                </small>
                                <div
                                  style={{
                                    marginTop: "18px",
                                    marginBottom: "18px",
                                  }}
                                >
                                  <SwitchButton
                                    width={120}
                                    height={50}
                                    textOn="Manual"
                                    textOff="Auto"
                                    onSwitch={() =>
                                      setIsServiceAddressAutoCompleteFinished(
                                        !isServiceAddressAutoCompleteFinished
                                      )
                                    }
                                  />
                                </div>
                                {!isServiceAddressAutoCompleteFinished && (
                                  <CanadaPostAutoCompleteAddress
                                    onAutoCompleteAddress={
                                      getServiceAddressAutoComplete
                                    }
                                    selectedCountry={"us"}
                                  />
                                )}
                                {isServiceAddressAutoCompleteFinished && (
                                  <>
                                    <InputContainerRow>
                                      <InputContainer>
                                        <Label>Unit No</Label>
                                        <Form.Control
                                          type="text"
                                          onChange={(
                                            event: BaseSyntheticEvent
                                          ) =>
                                            dispatch(
                                              updateActivationInfo({
                                                us_portin_service_unitNo:
                                                  event.target.value,
                                              }) as any
                                            )
                                          }
                                          value={
                                            activationInfoPrepaid.us_portin_service_unitNo
                                          }
                                        />
                                      </InputContainer>
                                      <InputContainer>
                                        <Label>
                                          Street Address
                                          <span style={{ color: colors.RED }}>
                                            *
                                          </span>
                                        </Label>
                                        <Form.Control
                                          type="text"
                                          value={
                                            activationInfoPrepaid.us_portin_service_street_address
                                          }
                                          onChange={(
                                            event: BaseSyntheticEvent
                                          ) =>
                                            dispatch(
                                              updateActivationInfo({
                                                us_portin_service_street_address:
                                                  event.target.value,
                                              }) as any
                                            )
                                          }
                                        />
                                      </InputContainer>
                                    </InputContainerRow>
                                    <InputContainerRow>
                                      <InputContainer>
                                        <Label>
                                          City
                                          <span style={{ color: colors.RED }}>
                                            *
                                          </span>
                                        </Label>
                                        <Form.Control
                                          type="text"
                                          onChange={(
                                            event: BaseSyntheticEvent
                                          ) =>
                                            dispatch(
                                              updateActivationInfo({
                                                us_portin_service_city:
                                                  event.target.value,
                                              }) as any
                                            )
                                          }
                                          value={
                                            activationInfoPrepaid.us_portin_service_city
                                          }
                                        />
                                      </InputContainer>
                                      <InputContainer>
                                        <Label>
                                          {checkCountry("stateProvince")}
                                          <span style={{ color: colors.RED }}>
                                            *
                                          </span>
                                        </Label>
                                        <StatesDropDownList
                                          value={usPortinState}
                                          onSelect={(state: any) => {
                                            setUSPortinState(state);
                                            dispatch(
                                              updateActivationInfo({
                                                us_portin_service_state_province:
                                                  state.code,
                                              }) as any
                                            );
                                          }}
                                        ></StatesDropDownList>
                                      </InputContainer>
                                    </InputContainerRow>
                                    <InputContainerRow>
                                      <InputContainer>
                                        <Label>
                                          Country
                                          <span style={{ color: colors.RED }}>
                                            *
                                          </span>
                                        </Label>
                                        <CountriesDropDownList
                                          onSelect={(event: any) =>
                                            dispatch(
                                              updateActivationInfo({
                                                us_portin_service_country:
                                                  event.countryName,
                                              }) as any
                                            )
                                          }
                                          defaultValue={
                                            activationInfoPrepaid.us_portin_service_country
                                          }
                                          onlyUSA={true}
                                        />
                                      </InputContainer>
                                      <InputContainer>
                                        <Label>
                                          {checkCountry("zipPostal")}
                                          <span style={{ color: colors.RED }}>
                                            *
                                          </span>
                                        </Label>
                                        <Form.Control
                                          type="text"
                                          onChange={(event) =>
                                            dispatch(
                                              updateActivationInfo({
                                                us_portin_service_zip_postal:
                                                  event.target.value,
                                              }) as any
                                            )
                                          }
                                          value={
                                            activationInfoPrepaid.us_portin_service_zip_postal
                                          }
                                        />
                                      </InputContainer>
                                    </InputContainerRow>
                                  </>
                                )}
                              </WideInputContainer>
                              <WideInputContainer $mobile={isMobile}>
                                <div
                                  style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "fit-content",
                                    marginRight: "auto",
                                    marginLeft: "20px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      localVars.isNotAPOBox
                                        ? faSquareCheck
                                        : faSquare
                                    }
                                    onClick={() =>
                                      dispatch(
                                        updateLocal({
                                          isNotAPOBox: !localVars.isNotAPOBox,
                                        }) as any
                                      )
                                    }
                                  />
                                  <div style={{ paddingLeft: "4px" }}>
                                    This is not a P.O. box
                                    <span style={{ color: colors.RED }}>*</span>
                                  </div>
                                </div>
                              </WideInputContainer>
                            </>
                          )}
                      </>
                    )}
                    {/* Canada port in */}
                    {localVars.isPortin && isCanada && (
                      <>
                        <div
                          style={{
                            width: isMobile ? "300px" : "640px",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ fontWeight: 600, fontSize: "14px" }}>
                            Provide more details about your current phone
                            number.
                          </div>
                          <div style={{ fontSize: "14px" }}>
                            *Notice for port in customers: the activation is not
                            available during the weekends and holidays.
                          </div>
                        </div>
                        <InputContainerRow
                          $vertical={isMobile}
                          style={{ marginTop: "20px" }}
                        >
                          <InputContainer>
                            <Label>Current Carrier</Label>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="primary"
                                className="w-100"
                              >
                                {localVars.portinCarrier}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {carriers.map((c) => (
                                  <Dropdown.Item
                                    key={c.key}
                                    onClick={() => handleCarrierMenuClick(c)}
                                  >
                                    {c.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            {invalidCarrier && (
                              <CarrierErrorMsg>
                                Sorry, the phone number you've entered is not
                                eligible for Port In.
                              </CarrierErrorMsg>
                            )}
                            {isFreedomPortin && (
                              <CarrierErrorMsg>
                                <div>
                                  Please provide the IMEI (15 digits) to port in
                                  your number.
                                </div>
                                <br />
                                <div>
                                  Dial *#06# to get your phone's IMEI number or
                                  find it in your phone's settings.
                                </div>
                              </CarrierErrorMsg>
                            )}
                          </InputContainer>
                          <InputContainer>
                            {isFreedomPortin ? (
                              <>
                                <Label>IMEI Number (15 digits)</Label>
                                <Form.Control
                                  type="text"
                                  maxLength={15}
                                  disabled={invalidCarrier}
                                  onChange={(event) => {
                                    dispatch(
                                      updateLocal({
                                        portinAccountNo: event.target.value,
                                      }) as any
                                    );
                                    dispatch(
                                      updateActivationInfo({
                                        portin_accountNo: event.target.value,
                                      }) as any
                                    );
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Label>Account Number</Label>
                                <Form.Control
                                  type="text"
                                  disabled={invalidCarrier}
                                  onChange={(event) => {
                                    dispatch(
                                      updateLocal({
                                        portinAccountNo: event.target.value,
                                      }) as any
                                    );
                                    dispatch(
                                      updateActivationInfo({
                                        portin_accountNo: event.target.value,
                                      }) as any
                                    );
                                  }}
                                />
                              </>
                            )}
                          </InputContainer>
                        </InputContainerRow>
                        <InputContainerRow $vertical={isMobile}>
                          <InputContainer>
                            <Label>Phone Number</Label>
                            <Form.Control
                              type="text"
                              disabled={invalidCarrier}
                              onChange={(event) => {
                                handleCanadianPortin(event);
                              }}
                            />
                            {invalidCanadianPortin && (
                              <CarrierErrorMsg>
                                We're sorry, port-in from Quebec is not
                                available.
                              </CarrierErrorMsg>
                            )}
                          </InputContainer>
                          <InputContainer />
                        </InputContainerRow>
                      </>
                    )}
                  </GrayContainer>
                </>
              )}
              {/* US Add On Start */}
              {country === constants.USA &&
              selectedPlan.planTypeD === "30" &&
              intlCallingAddons.length > 0 ? (
                <>
                  <Subtitle style={{ marginTop: "30px", marginBottom: "30px" }}>
                    Add-Ons
                  </Subtitle>
                  <GrayContainer
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "20px",
                      paddingBottom: "30px",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {intlCallingAddons.map((a) => (
                      <div
                        className="w-100 d-flex align-items-center justify-content-between"
                        style={{ maxWidth: "640px" }}
                        key={a?.i_subscription}
                      >
                        <label style={{ cursor: "pointer" }}>
                          <input
                            type="checkbox"
                            name="add-on-option"
                            checked={
                              selectedIntlCallingAddon === a?.i_subscription
                            }
                            value={a?.i_subscription}
                            onChange={(e) => {
                              if (
                                selectedIntlCallingAddon === a?.i_subscription
                              ) {
                                setSelectedIntlCallingAddon(null);
                              } else {
                                setSelectedIntlCallingAddon(a?.i_subscription);
                              }
                            }}
                          />
                          <span className="ms-2">{a?.end_user_name}</span>
                        </label>
                        <span>
                          ${a?.product_subscription?.activation_fee?.toFixed(2)}
                          /month
                        </span>
                      </div>
                    ))}
                  </GrayContainer>
                </>
              ) : null}
              {/* US Add On End */}
              {country !== constants.USA && (
                <>
                  <AlignRow
                    style={{ justifyContent: "center", marginTop: "16px" }}
                  >
                    {cemChecked ? (
                      <FontAwesomeIcon
                        style={{ color: "#333333" }}
                        icon={faSquareCheck}
                        fontSize={32}
                        onClick={() =>
                          dispatch(
                            updateActivationInfo({ consent_cem: false }) as any
                          )
                        }
                      ></FontAwesomeIcon>
                    ) : (
                      <FontAwesomeIcon
                        style={{ color: "#333333" }}
                        icon={faSquare}
                        fontSize={32}
                        onClick={() =>
                          dispatch(
                            updateActivationInfo({ consent_cem: true }) as any
                          )
                        }
                      ></FontAwesomeIcon>
                    )}
                    <div style={{ fontSize: "12px", paddingLeft: 10 }}>
                      I have read and understand the{" "}
                      <span
                        style={{
                          color: "#01bae5",
                          cursor: "pointer",
                          textDecorationLine: "underline",
                        }}
                        onClick={() =>
                          window.open(
                            "https://gophonebox.com/terms-of-service/"
                          )
                        }
                      >
                        terms & conditions
                      </span>{" "}
                      and agree to receive personalized commercial
                      communications from PhoneBox.
                    </div>
                  </AlignRow>
                </>
              )}
              {emailMatchError && (
                <ErrorMessage>Your email addresses must match!</ErrorMessage>
              )}
              {emailExistError && (
                <ErrorMessage>
                  Your email address is already in use!
                </ErrorMessage>
              )}
              {emailSpecialCharError && (
                <ErrorMessage>
                  Your email address must contain only latin letters, digits,
                  '-', '_', '@', and '.'.
                </ErrorMessage>
              )}
              {errorMessage.length > 0 && (
                <ErrorMessage>{errorMessage}</ErrorMessage>
              )}
              {cemError && (
                <ErrorMessage>
                  Please agree to the terms and conditions.
                </ErrorMessage>
              )}
            </div>
          )}
          {/* SECTION THREE  // PAYMENT */}
          {step === Step.Third && (
            <div
              style={{
                margin: isMobile ? "50px 10px 0px 10px" : "0px 0px 0px 0px",
              }}
            >
              <Subtitle>Payment</Subtitle>
              <div style={{ marginBottom: "20px" }}>
                You are just a few clicks away!
              </div>
              <GrayContainer style={{ padding: "20px" }}>
                <div
                  style={{
                    width: "650px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <AlignRowJustify>
                    <PaymentMethod
                      onClick={() =>
                        setSelectedPaymentMethod(PAYMENT_METHOD_CREDIT_CARD)
                      }
                    >
                      <input
                        type="radio"
                        className="radio-blue"
                        checked={
                          selectedPaymentMethod === PAYMENT_METHOD_CREDIT_CARD
                        }
                        readOnly
                      />
                      <img
                        src="/images/payment/credit-card.png"
                        alt="credit card icon"
                        width="36px"
                        style={{ margin: "0px 20px 0px 10px" }}
                      />
                      <div>Credit Card</div>
                    </PaymentMethod>
                    <PaymentMethod
                      onClick={() =>
                        setSelectedPaymentMethod(PAYMENT_METHOD_PAYPAL)
                      }
                    >
                      <input
                        type="radio"
                        className="radio-blue"
                        checked={
                          selectedPaymentMethod === PAYMENT_METHOD_PAYPAL
                        }
                        readOnly
                      />
                      <img
                        src="/images/payment/paypal.png"
                        alt="paypal icon"
                        width="36px"
                        style={{ margin: "0px 20px 0px 10px" }}
                      />
                      <div>Paypal</div>
                    </PaymentMethod>
                  </AlignRowJustify>
                  {country === constants.CANADA && (
                    <AlignRowJustify style={{ marginTop: "15px" }}>
                      <PaymentMethod
                        onClick={() =>
                          setSelectedPaymentMethod(PAYMENT_METHOD_ALIPAY_QR)
                        }
                      >
                        <input
                          type="radio"
                          className="radio-blue"
                          checked={
                            selectedPaymentMethod === PAYMENT_METHOD_ALIPAY_QR
                          }
                          readOnly
                        />
                        <img
                          src="/images/payment/alipay.jpg"
                          alt="alipay icon"
                          width="36px"
                          style={{ margin: "0px 20px 0px 10px" }}
                        />
                        <div>AliPay</div>
                      </PaymentMethod>
                      <PaymentMethod
                        onClick={() =>
                          setSelectedPaymentMethod(PAYMENT_METHOD_WX_NATIVE)
                        }
                      >
                        <input
                          type="radio"
                          className="radio-blue"
                          checked={
                            selectedPaymentMethod === PAYMENT_METHOD_WX_NATIVE
                          }
                          readOnly
                        />
                        <img
                          src="/images/payment/wechat.png"
                          alt="wechat icon"
                          width="36px"
                          style={{ margin: "0px 20px 0px 10px" }}
                        />
                        <div>WeChat Pay</div>
                      </PaymentMethod>
                    </AlignRowJustify>
                  )}
                </div>
              </GrayContainer>
              {/** QR code pop-up */}
              <CustomPopup
                onClose={popupCloseHandler}
                show={visibility}
                title={isAlipay ? "Alipay QR Code" : "WeChat QR Code"}
                widthPct={30}
              >
                <Image src={qrCode} />
              </CustomPopup>
              <CustomPopup
                onClose={postpaidPopupCloseHandler}
                show={postpaidModalVisibility}
                title={"Partial Plan"}
                widthPct={50}
              >
                <div style={{ padding: "24px" }}>
                  <div>
                    From <b>{activationInfoPrepaid.prorateStartDate}</b> (your
                    activation date) to{" "}
                    <b>{activationInfoPrepaid.prorateEndDate}</b> you will
                    paying{" "}
                    <b style={{ color: colors.BLUE }}>
                      ${summaryData.prorateFee} + tax
                    </b>{" "}
                    for the following features:
                  </div>
                  <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                    <PopupBulletItem>
                      • Up to 1,000 minutes of International Calling
                    </PopupBulletItem>
                    <PopupBulletItem>• Unlimited Messages</PopupBulletItem>
                    <PopupBulletItem>
                      • {activationInfoPrepaid.prorateDataCap} of Prorated Data
                    </PopupBulletItem>
                  </div>
                  <div>
                    This is because your activation date is during our billing
                    cycle.
                  </div>
                  <div>
                    Your full billing cycle starts on{" "}
                    {activationInfoPrepaid.chargeStart}.
                  </div>
                </div>
                <GradientButton
                  $isCanada={isCanada}
                  style={{ margin: "auto" }}
                  onClick={postpaidPopupCloseHandler}
                >
                  Close
                </GradientButton>
              </CustomPopup>
              <div style={{ marginTop: "20px" }}>
                {noPaymentMethodSelectedError && (
                  <ErrorMessage>Kindly choose a payment method.</ErrorMessage>
                )}
              </div>
            </div>
          )}
        </SmallContainer>
      </div>
      {/* PAY // NEXT CONTROLS */}
      <AlignRow style={{ justifyContent: "center" }}>
        {step === Step.Second && (
          <GradientButton $isCanada={isCanada} onClick={onClickNext}>
            <div style={{ marginRight: "4px" }}>Next</div>
            {loading && <Spinner variant="light" size="sm" />}
          </GradientButton>
        )}
        {step === Step.Third && (
          <RedButton onClick={onClickPay}>
            <div style={{ marginRight: "4px" }}>Pay</div>
            {loading && <Spinner variant="light" size="sm" />}
          </RedButton>
        )}
      </AlignRow>
      <BottomBannerContainer>
        {step === Step.First && (
          <>
            <Container
              style={{
                gridColumn: "1 / span 4",
                width: "100%",
                height: "100%",
              }}
            >
              <TakeMeThere />
            </Container>
            <Container
              style={{
                gridColumn: "5 / span 8",
                width: "100%",
                height: "100%",
              }}
            >
              <IMEICheck country={country} />
            </Container>
          </>
        )}
        <SmallContainer
          style={{ gridColumn: "1 / span 12", width: "100%", height: "100%" }}
        >
          <LearnMoreSection country={country} />
        </SmallContainer>
      </BottomBannerContainer>
      {isMobile ? <MobileFooter /> : <Footer />}
    </div>
  );
}

const BackgroundImage = styled.div`
  background-image: url("/images/esimBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 180px;
`;

const ImageOverlay = styled.div<{ $isCanada: boolean }>`
  ${(props) =>
    props.$isCanada
      ? "background-image: linear-gradient(rgba(0, 147, 233, 0.6), rgba(54, 194, 207, 0.6));"
      : ""}
  ${(props) =>
    props.$isCanada ? "" : "background-color: rgba(130, 195, 109, 0.6);"}
  position: absolute;
  top: 0;
`;

const BGImageOverlay = styled(ImageOverlay)`
  width: 100%;
  height: 180px;
  z-index: 0;
`;

const CardImageOverlay = styled(ImageOverlay)`
  height: 185px;
  width: 250px;
  border-radius: 5px;
`;

const PlanFeaturesCard = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  background-color: #fff;
  top: -40px;
  left: 0;
  right: 0;
  margin-left: ${(props) => (props.$isMobile ? "60px" : "auto")};
  margin-right: ${(props) => (props.$isMobile ? "60px" : "auto")};
  padding: 20px;
  border-radius: 10px;
  max-width: 700px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.05),
    0 20px 40px 0 rgba(0, 0, 0, 0.09);
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1400px) {
    max-width: 1320px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    max-width: 1140px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 960px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 720px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    max-width: 540px;
  }
  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

const Title = styled.h3`
  text-align: center;
  padding-top: 30px;
  font-weight: 600;
  color: #fff;
  font-size: 35px;
`;

const Divider = styled.div<{ $horizontal?: boolean }>`
  width: ${(props) => (props.$horizontal ? "auto" : "1px")};
  background-color: #ccc;
  height: ${(props) => (props.$horizontal ? "1px" : "auto")};
`;

const Header = styled.div<{ color?: string }>`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => (props.color ? props.color : "#000")};
`;

const Subtitle = styled.div<{ color?: string }>`
  font-weight: 600;
  color: ${(props) => (props.color ? props.color : "#000")};
`;

const GradientButton = styled.div<{ $isCanada: boolean }>`
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
  background-image: ${(props) =>
    props.$isCanada
      ? "linear-gradient(#0093E9, #36C2CF)"
      : "linear-gradient(#82C36D, #82C36D)"};
  border-radius: 10px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RedButton = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
  background-color: #e93f25;
  border-radius: 10px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DataLabel = styled.div<{
  $color: string;
  $lightColor: string;
  $small?: boolean;
}>`
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$lightColor};
  padding: 5px 10px;
  height: fit-content;
  border-radius: 10px;
  width: fit-content;
  font-weight: 600;
  font-size: 20px;
`;

const SmallContainer = styled.div`
  margin: auto;

  @media (min-width: 1200px) {
    max-width: 1120px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 860px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 720px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

const BlueContainer = styled(SmallContainer)`
  background-color: rgba(4, 148, 202, 0.1);
  border-radius: 10px;
`;

const GrayContainer = styled(SmallContainer)`
  background-color: #f7f7f7;
  border-radius: 10px;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin: 15px 0px 5px 0px;
`;

const DetailItem = styled.li`
  font-size: 14px;
  font-weight: 300;
`;

const NavHeader = styled.div<{
  $highlightStep: Step;
  $activeStep: Step;
  $color: string;
}>`
  cursor: initial;
  width: 50%;
  text-align: center;
  padding: 4px;
  font-weight: 600;
  transition: 0.3s ease-in-out;
  color: ${(props) =>
    props.$highlightStep === props.$activeStep ? props.$color : "#E5E5E5"};
`;

const HR = styled.div<{ color: string; step: Step }>`
  height: 0.25rem;
  width: 50%;
  margin: 0;
  background-color: ${(props) => props.color};
  border: none;
  transition: 0.3s ease-in-out;
  margin-left: ${(props) => (props.step === Step.Third ? "50%" : "0%")};
`;

const ErrorMessage = styled.div`
  color: ${colors.RED};
  text-align: center;
`;

const PaymentMethod = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const InputContainer = styled.div`
  width: 300px;
  margin: 0px 20px;
`;

const InputContainerRow = styled.div<{ $vertical?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$vertical ? "column" : "row")};
`;

const BottomBannerContainer = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 120px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 32px;

  @media (min-width: 1200px) {
    max-width: 1120px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 860px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 720px;
  }

  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0 20px;
    margin-top: 50px;
    margin-bottom: 120px;
    display: block;
  }
`;

const InternationalCallingLink = styled.a`
  font-family: "Poppins", sans-serif;
  color: #0494ca;
  display: inline-block;
  :hover {
    cursor: pointer;
    color: #0494ca;
  }
`;

const AlignRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AlignRowJustify = styled(AlignRow)`
  justify-content: space-between;
`;

const CountrySelectRadio = styled(AlignRowJustify)<{
  $countrycolor: string;
  $lightcountrycolor: string;
  selected: boolean;
}>`
  margin-top: 4px;
  border-radius: 10px;
  width: 180px;
  border: 1px solid
    ${(props) => (props.selected ? props.$countrycolor : "#fff")};
  padding: 5px 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.$lightcountrycolor : "#fff"};
`;

const IconPlanTag = styled.div<{ selected: boolean; color: string }>`
  border-style: solid;
  border-radius: 10px;
  border-color: ${(props) => props.color};
  border-width: 1px;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  color: ${(props) => (props.selected ? "#FFF" : props.color)};
  background-color: ${(props) => (props.selected ? props.color : "#FFF")};
  width: fit-content;
  height: fit-content;
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  margin-right: 12px;
  position: relative;
`;

const PlanTag = styled.div<{ selected: boolean; color: string }>`
  border-style: solid;
  border-radius: 10px;
  border-color: ${(props) => props.color};
  border-width: 1px;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  color: ${(props) => (props.selected ? "#FFF" : props.color)};
  background-color: ${(props) => (props.selected ? props.color : "#FFF")};
  width: 200px;
  height: fit-content;
  padding: 12px 24px;
  display: block;
  font-weight: 600;
  cursor: pointer;
  margin-right: 12px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 12px;
  }

  .plan-tag-arrow {
    position: absolute;
    bottom: -8px;
    height: 16px;
    width: 16px;
    background-color: ${(props) => props.color};
    transform: rotateY(0deg) rotate(45deg);
  }
`;

const PopupBulletItem = styled.div`
  padding: 4px;
`;

const CarrierErrorMsg = styled.div`
  color: red;
  margin-top: 12px;
  text-align: center;
`;

const WideInputContainer = styled.div<{ $mobile: boolean }>`
  width: ${(props) => (props.$mobile ? "300" : "640")}px;
`;
