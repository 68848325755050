import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { colors } from "../utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useEffect, useState } from "react";
import { ApiRequest } from "../types/models/ApiRequest";
import { proxyApi } from "../utilities/proxy-api";
import { ApiErrorCode } from "../types/enums/ApiErrorCode";
import { getPaymentMethodType } from "../utilities/utils";
import useIsMobile from "../hooks/useIsMobile";
import { Image } from "react-bootstrap";
import { updateLocal } from "../redux/actions/localActions";
import { Step } from "../types/enums/Step";
import { Helmet } from "react-helmet";

export default function PaymentThankYou() {
  const activationInfoPrepaid = useSelector((state: RootState) => state.activationInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const countryId = activationInfoPrepaid.service_countryId;
  const [loading, setLoading] = useState(false);
  const startDateForDisplay = activationInfoPrepaid?.startDate?.split(" ")[0] || "";
  const isCanada = countryId !== 247;
  const countryColor = isCanada? colors.BLUE : colors.GREEN;
  const lightCountryColor = isCanada ? colors.LIGHTBLUE : colors.LIGHTGREEN;
  dispatch(updateLocal({ step: Step.First }) as any);

  useEffect(() => {
    if (countryId === 247) { // check for USA ONLY
      //complete checkout request...
      const completeCheckoutRequest = new ApiRequest({
        account_id: activationInfoPrepaid.us_i_account,
        delivery_complete: true,
        is_esim: true,
        plintron_plan_id: activationInfoPrepaid.us_plintron_plan_id,
        shipping_id: 10,  // 10 means regular with no fee
        sim_card_number: "",
        email: activationInfoPrepaid.email,
        activation_date: activationInfoPrepaid.us_activation_date,
        payment_method: getPaymentMethodType(activationInfoPrepaid.payment_method),
        customer_id: activationInfoPrepaid.us_i_customer,
        promo_code: "",
        int_call_id: activationInfoPrepaid.selected_intl_add_on
      });
      proxyApi.checkout.completeCheckout(completeCheckoutRequest).then(res => {
        if (!res.success && res.error) {
          console.error(res.error);
          if (res.error.code === ApiErrorCode.PaymentNotCompleted.toString()) {
            // goTo("/404");
            alert("Error! Something went wrong with complete checkout!");

            return;
          }
          setLoading(false);
          return;
        }
        setLoading(false);
      }).catch(err => {
        console.error(err);
        setLoading(false);
      });
    }
  })

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
            // <![CDATA[
            var _dcq = _dcq || [];
            var _dcs = _dcs || {};
            _dcs.account = '8316321';
          
            (function() {
              var dc = document.createElement('script');
              dc.type = 'text/javascript'; dc.async = true;
              dc.src = '//tag.getdrip.com/8316321.js';
              var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(dc, s);
            })();
            // ]]>
          `}
        </script>
      </Helmet>
      <SmallContainer>
        <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center" }}>
          <div style={{ maxWidth: isMobile ? "300px" : "500px", margin: isMobile ? "auto" : "0px 0px" }}>
            <img src="/images/thank-you/big-phone.png" alt="mobile phone" style={{ width: "100%" }}></img>
          </div>
          <div style={{ width: "50px", height: "30px" }} />
          <div style={{ maxWidth: "500px", display: "flex", flexDirection: "column", justifyContent: "space-between", textAlign: isMobile ? "center" : "left", margin: "auto" }}>
            <div>
              <ThankYouTitle $color={countryColor} $lightColor={lightCountryColor}>Welcome to PhoneBox!</ThankYouTitle>
              <div style={{ margin: "16px 0px" }}>
                <div style={{ fontWeight: 600 }}>Payment Approved.</div>
                <div>Your confirmation email is on its way with all the details you need.</div>
                <div>Your eSIM QR code will be available on the service start date:</div>
                <div style={{ fontWeight: 600 }}>{startDateForDisplay}</div>
              </div>
              <div style={{ margin: "16px 0px" }}>
                <div>Don't forget to sign up for our user-friendly <span style={{ fontWeight: 600 }}>self-service portal</span> – your key to effortless account management and real-time data-tracking.</div>
              </div>
            </div>
            <div style={{ width: 'fit-content', marginRight: "auto", display: "flex", flexDirection: "row", margin: isMobile ? "auto" : "0" }}>
              <GradientButton onClick={() => isCanada ? window.open("https://myaccount.gophonebox.com/login") : window.open("https://us.myaccount.gophonebox.com")}>Manage Your Account</GradientButton>
              <div style={{ width: "30px" }}/>
              <OutlineButton onClick={() => navigate("/")}>Home</OutlineButton>
            </div>
          </div>
        </div>
        <SupportWrapper style={{ margin: isMobile ? "48px 24px" : "48px 0px" }}>
          <div style={{ display: "flex", flexDirection: "row", width: "fit-content" }}>
            <img src="/images/thank-you/support.png" alt="phonebox support logo" height="70px" />
            <div style={{ marginLeft: "12px", width: "300px" }}>
              <div style={{ fontWeight: 600 }}>24/7 Support</div>
              <div>Having issues with your eSim during your trip? We are here to help 24/7 in 12 different languages.</div>
            </div>
          </div>
          <ContactWrapper>
            <Image src="/images/thank-you/mail.png" width={30} height={30} />
            <div style={{ lineHeight: 0.5, marginLeft: 10 }}>
              <p style={{ fontWeight: "bold" }}>
                Email:
              </p>
              <p>services@gophonebox.com</p>
            </div>
          </ContactWrapper>
          <ContactWrapper>
            <Image src='/images/thank-you/phone.png' width={30} height={30} />
            <div style={{ lineHeight: 0.5, marginLeft: 10 }}>
              <p style={{ fontWeight: "bold" }}>
                Phone Number:
              </p>
              <p>+1-855-886-0505</p>
            </div>
          </ContactWrapper>
        </SupportWrapper>
      </SmallContainer>
    </>
  )
}

const SmallContainer = styled.div`
  margin: auto;

  @media (min-width: 1200px) {
    margin-top: 120px;
    max-width: 1120px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-top: 120px;
    max-width: 860px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-top: 80px;
    max-width: 720px;
  }
  @media (max-width: 767px) {
    margin-top: 20px;
    max-width: 100%;
  }
`

const GradientButton = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  color: #fff;
  background-image: linear-gradient(#0093E9, #36C2CF);
  border-radius: 10px;
  width: fit-content;
`

const OutlineButton = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  color: ${colors.BLUE};
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ThankYouTitle = styled.div<{ $color: string, $lightColor: string }>`
  color: ${props => props.$color};
  background-color: ${props => props.$lightColor};
  padding: 5px 10px;
  height: fit-content;
  border-radius: 10px;
  width: fit-content;
  font-weight: 600;
  font-size: 40px;
`

const ContactWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: 991px) {
    margin-left: 50px;
  }
`;

const SupportWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  
  @media (max-width: 991px) {
    flex-direction: column;
  }
`