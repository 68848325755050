import axios from "axios";

let baseURL = process.env.REACT_APP_PROXY_API;
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "test-myesim.gophonebox.com" ||
  /^witty-sky.*azurestaticapps\.net$/.test(window.location.hostname)
) {
  baseURL = "https://phoneboxproxy.azurewebsites.net";
}

const instance = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {},
});

instance.interceptors.request.use(async (config) => {
  try {
    return config;
  } catch (e) {
    console.error(e);
  }
});

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (!response.data.success) {
      console.warn(response.config.url, response.data);
    }
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
